import { useCallback, useState } from 'react'
import { getItem, setItem } from 'utils/storage'

interface StorageItem<T> {
  value: T
}

const getLocalState = <T>(key: string, initialState: T): T => {
  const parsedItem = getItem(key) as StorageItem<T>

  if (parsedItem) {
    return parsedItem.value
  }

  return initialState
}

export const useLocalState = <T>(
  key: string,
  initialState: T
): [T, (newState: T | ((currentState: T) => T)) => void] => {
  const [reactState, setReactState] = useState(() =>
    getLocalState(key, initialState)
  )

  const setState = useCallback(
    (newState: T | ((current: T) => T)) => {
      let stateAux: T | undefined

      if (newState instanceof Function) {
        stateAux = newState(reactState)
      } else {
        stateAux = newState
      }

      const parsedItem: StorageItem<T> = {
        value: stateAux,
      }

      setItem(key, parsedItem)
      setReactState(stateAux)
    },
    [key, reactState]
  )

  return [reactState, setState]
}
