import { languagesInfo } from 'constants/languageOptions'
import { IntlShape } from 'react-intl'
import { NavigateFunction } from 'react-router-dom'
import { getDateId } from 'utils/getDateId'
import {
  GAME_PROPERTIES,
  INITIAL_LOCAL_STATE,
  SECOND_LEVEL_ROUTE,
} from './game.constants'
import { LocalState, Path } from './game.types'
import { getItem, removeItem, setItem } from 'utils/storage'

interface StorageItem<T> {
  dateId: string
  languageCode: string
  value: T
  expiration?: number
}

const getStorageKey = (
  key: string,
  languageCode: string,
  challengeId: string
) => {
  let storageKey = key + '-' + languageCode.toUpperCase()

  if (challengeId) {
    storageKey += '-' + challengeId
  }
  return storageKey
}

export const navigateArticle = (
  navigate: NavigateFunction,
  article: string,
  params: URLSearchParams
) => {
  const link = `${SECOND_LEVEL_ROUTE}/` + article + '?' + params.toString()
  navigate(link)
}

export const setLocalStorageState = <T>(
  key: string,
  languageCode: string,
  challengeId: string,
  value: T
) => {
  const parsedItem: StorageItem<T> = {
    value: value,
    dateId: getDateId(),
    languageCode,
  }

  setItem(getStorageKey(key, languageCode, challengeId), parsedItem)
}

export const getLocalStorageProperty = <T>(
  propertyId: string,
  languageCode: string,
  challengeId: string
) => {
  const dateId = getDateId()
  const parsedItem = getItem(
    getStorageKey(propertyId, languageCode, challengeId)
  ) as StorageItem<T>

  if (parsedItem) {
    if (
      parsedItem.dateId === dateId &&
      parsedItem.languageCode === languageCode
    ) {
      return parsedItem.value
    }
  }

  return undefined
}

export const clearLanguageLocalStorageState = (
  lang: string,
  challengeId: string
) => {
  Object.values(GAME_PROPERTIES).forEach((localId) => {
    removeItem(getStorageKey(localId, lang, challengeId))
  })
}

export const clearLocalStorageState = (
  intl: IntlShape,
  challengeId: string
) => {
  Object.values(GAME_PROPERTIES).forEach((localId) => {
    languagesInfo.forEach((lang) => {
      removeItem(getStorageKey(localId, lang.code, challengeId))
    })
  })
}

export const getInitialLocalStorageState = (
  languageCode: string,
  challengeId: string
) => {
  const state = JSON.parse(JSON.stringify(INITIAL_LOCAL_STATE))

  Object.entries(GAME_PROPERTIES).forEach(([key, localId]) => {
    const localValue = getLocalStorageProperty<unknown>(
      localId,
      languageCode,
      challengeId
    )

    if (localValue) {
      state[key] = localValue
    }
  })

  return { ...state, startTime: Date.now() } as LocalState
}

export const getWinningPath = (path: Path, targetArticle: string) => {
  const winningPath: string[] = [targetArticle]

  const winningNode = path[targetArticle]

  let currentNode = winningNode

  while (currentNode) {
    winningPath.unshift(currentNode)
    currentNode = path[currentNode]
  }

  return winningPath.map((node) => node.replaceAll('_', ' '))
}
