export const setItem = (key: string, value: string | object) => {
  try {
    let newValue: string = ''

    if (typeof value === 'object') {
      newValue = JSON.stringify(value)
    } else {
      newValue = value
    }

    localStorage.setItem(key, newValue)
  } catch (e) {}
}

export const getItem = (key: string, raw = false) => {
  try {
    const unparsedItem = localStorage.getItem(key)
    if (unparsedItem) {
      return raw ? unparsedItem : JSON.parse(unparsedItem)
    }
  } catch (e) {
    return null
  }
}

export const removeItem = (key: string) => {
  try {
    localStorage.removeItem(key)
  } catch (e) {}
}
