import axios from 'axios'

const WIKIPEDIA_API_URL =
  '/w/api.php?format=json&action=query&prop=categories&origin=*&clshow=!hidden&cllimit=50&redirects=1'

const getWikipediaURL = (page: string, languageCode: string) =>
  `https://${languageCode}.wikipedia.org` +
  WIKIPEDIA_API_URL +
  `&titles=${page}`

interface CategoriesResponse {
  query: {
    pages: Record<string, { categories: { title: string }[] }>
  }
}

export const fetchArticleCategories = async (
  page: string,
  languageCode: string
): Promise<string[]> => {
  try {
    return await axios
      .get<CategoriesResponse>(getWikipediaURL(page, languageCode), {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
      .then((response) => {
        const categories =
          Object.values(response.data.query.pages)[0]?.categories ?? []

        return categories.map((category) => category.title)
      })
  } catch (e) {
    console.error(e)
    return []
  }
}
