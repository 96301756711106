import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import {
  ArticleContainer,
  Link,
  Content,
  ContentContainer,
} from './article.styles'
import { useModals } from 'providers/ModalsProvider'
import { ArticleContent } from 'components/game/game.types'

interface ArticleProps {
  onError: (e: Error) => void
  setHasDrawn: () => void
  scrollTop: () => void
  onChange: (newArticle: string) => void
  processUrlArticle: (urlArticle: string) => void
  setIsArticleLoading: (isLoading: boolean) => void
  articleContent: ArticleContent
  currentArticle: string | undefined
  currentLanguage: string
  isGameLoading: boolean
  isArticleLoading: boolean
}

const ARTICLE_PREFIX = 'wiki/'

export const Article = ({
  articleContent,
  currentLanguage,
  currentArticle,
  isGameLoading,
  isArticleLoading,
  onError,
  onChange,
  processUrlArticle,
  setIsArticleLoading,
  scrollTop,
  setHasDrawn,
}: ArticleProps) => {
  const intl = useIntl()
  const { article } = useParams()
  const [title, setTitle] = useState('')
  const [categories, setCategories] = useState<string[]>([])
  const [subcategories, setSubcategories] = useState<string[]>([])
  const [articlesOfCategory, setArticlesOfCategory] = useState<string[]>([])
  const { animationsDisabled } = useModals()

  const contentRef = useRef<HTMLDivElement | null>(null)
  const articleRef = useRef<HTMLDivElement | null>(null)
  const animationsDisabledRef = useRef(animationsDisabled)

  useEffect(() => {
    animationsDisabledRef.current = animationsDisabled
  }, [animationsDisabled])

  useEffect(() => {
    if (!isGameLoading) {
      if (article) {
        processUrlArticle(article)
      }
    }
  }, [processUrlArticle, article, isGameLoading])

  useEffect(() => {
    const { articlesOfCategory, categories, content, subcategories } =
      articleContent
    if (!isGameLoading) {
      const currentContent = contentRef.current
      if (currentContent && currentArticle) {
        // Content
        const contentHTML = content.html.replaceAll(
          /<noscript>|<\/noscript>|bgcolor="[^"]*"|(background-color|background|border-color|color):[^(;|}|>)]*(;)/gm,
          ''
        )
        setContentStyles({ opacity: 0, filter: 'blur(3px)' })

        const initializeArticle = () => {
          currentContent.innerHTML = contentHTML
          scrollTop()
          setTitle(content.title)

          // Categories
          setCategories(categories)

          // Subcategories
          setSubcategories(subcategories)

          // Articles
          setArticlesOfCategory(articlesOfCategory)

          // Common
          setIsArticleLoading(false)
          setHasDrawn()
          setContentStyles({ opacity: 1, filter: 'blur(0px)' })
        }

        if (animationsDisabledRef.current) {
          initializeArticle()
        } else {
          setTimeout(() => {
            initializeArticle()
          }, 125)
        }
      }
    }
  }, [
    articleContent,
    onError,
    currentArticle,
    contentRef,
    articleRef,
    currentLanguage,
    isGameLoading,
    setIsArticleLoading,
    scrollTop,
    setHasDrawn,
  ])

  useEffect(() => {
    const currentContent = contentRef.current

    if (currentContent) {
      const handleClick = (e: MouseEvent) => {
        e.preventDefault()
        if (e && !isGameLoading && !isArticleLoading) {
          const target = e.target as HTMLElement

          if (target.tagName === 'A') {
            const anchor = target as HTMLAnchorElement
            const href = anchor.href
            const literalHref = anchor.getAttribute('href')

            // Wikipedia Link
            const internal = href.includes(window.location.origin)
            const prefixIndex = href.indexOf(ARTICLE_PREFIX)

            if (internal && prefixIndex !== -1) {
              const articleIndex = prefixIndex + ARTICLE_PREFIX.length
              const hashtagIndex = href.indexOf('#')

              const articleName = href.slice(
                articleIndex,
                hashtagIndex !== -1 ? hashtagIndex : undefined
              )
              if (
                !articleName.includes(':') &&
                literalHref &&
                literalHref[0] !== '#'
              ) {
                const decodedName = decodeURI(articleName)
                onChange(decodedName)
              }
            }
            // Article Anchor
            const relativehref = href.split(window.location.href)[1]

            if (relativehref && relativehref[0] === '#') {
              const decodedhref = CSS.escape(decodeURI(relativehref.slice(1)))
              const target = currentContent.querySelector('#' + decodedhref)
              target?.scrollIntoView({ behavior: 'smooth' })
            }
          } else {
            const anchor = e
              .composedPath()
              .find((e) => e && (e as HTMLAnchorElement).href) as
              | HTMLAnchorElement
              | undefined

            anchor?.click()
          }
        }
      }
      currentContent.addEventListener('click', handleClick)
      return () => currentContent.removeEventListener('click', handleClick)
    }
  }, [contentRef, onChange, isGameLoading, isArticleLoading])

  const [contentStyles, setContentStyles] = useState({
    opacity: 0,
    filter: 'blur(3px)',
  })

  return (
    <ArticleContainer
      id='article'
      style={contentStyles}
      ref={articleRef}
      isArticleLoading={isArticleLoading}
      animationsDisabled={animationsDisabledRef.current}
    >
      <Helmet>
        <meta name='robots' content='noindex' />
      </Helmet>
      <ContentContainer>
        {title && <h1>{title}</h1>}
        <Content ref={contentRef} />
        <>
          {!!articlesOfCategory.length && (
            <>
              <h2>
                {intl.formatMessage({
                  id: 'category-articles',
                  defaultMessage: 'Articles in Category',
                })}
              </h2>
              <ul>
                {articlesOfCategory.map((article) => (
                  <li key={article}>
                    <Link onClick={() => onChange(article)}>{article}</Link>
                  </li>
                ))}
              </ul>
            </>
          )}
          {!!subcategories.length && (
            <>
              <h2>
                {intl.formatMessage({
                  id: 'subcategories',
                  defaultMessage: 'Subcategories in Category',
                })}
              </h2>
              <ul>
                {subcategories.map((subcategory) => (
                  <li key={subcategory}>
                    <Link onClick={() => onChange(subcategory)}>
                      {subcategory}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
          {!!categories.length && (
            <>
              <h2>
                {intl.formatMessage({
                  id: 'related-categories',
                  defaultMessage: 'Related Categories',
                })}
              </h2>
              <ul>
                {categories.map((category) => (
                  <li key={category}>
                    <Link onClick={() => onChange(category)}>{category}</Link>
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      </ContentContainer>
    </ArticleContainer>
  )
}
