import { createGlobalStyle, css } from 'styled-components'

export const GlobalStyle = createGlobalStyle`

  a:focus, button:focus{
    outline:none;
    box-shadow: 0px 0px 0px 2px var(--primary-20);
  }

  .ReactModal__Overlay {
      opacity: 0;
      transition: opacity 200ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }

 body {
    margin: 0px;
    font-family: 'Roboto Mono', monospace;
    overflow: hidden;
	  color: var(--font-color);
    background:var(--background);
  }

  * {
    font-family: 'Roboto Mono', monospace;
  }

  a {
    text-decoration:none;
    color: var(--primary-20);
    font-weight: 500;
  }

  .responsive-modal{
    position: fixed;
    width: 520px;
    max-height: 100%;

    @media (max-width: 600px) {
      height: 100%;
      width: 100%;
    }
  }

  :root{
    ${({ theme }) => css`
      --primary-0: ${theme.colors.primary[0]};
      --primary-10: ${theme.colors.primary[10]};
      --primary-20: ${theme.colors.primary[20]};

      --secondary-0: ${theme.colors.secondary[0]};
      --secondary-10: ${theme.colors.secondary[10]};
      --secondary-20: ${theme.colors.secondary[20]};
      --secondary-30: ${theme.colors.secondary[30]};

      --neutral-0: ${theme.colors.neutrals[0]};
      --neutral-10: ${theme.colors.neutrals[10]};
      --neutral-20: ${theme.colors.neutrals[20]};
      --neutral-30: ${theme.colors.neutrals[30]};
      --neutral-100: ${theme.colors.neutrals[100]};
      --neutral-200: ${theme.colors.neutrals[200]};
      --neutral-300: ${theme.colors.neutrals[300]};
      --neutral-400: ${theme.colors.neutrals[400]};

      --black: rgb(40, 40, 40);

      /** Button */
      --button-background-primary: ${theme.colors.buttonBackground.primary};
      --button-background-secondary: ${theme.colors.buttonBackground.secondary};
      --button-border-primary: ${theme.colors.buttonBorder.primary};
      --button-border-secondary: ${theme.colors.buttonBorder.secondary};

      /** Modal */
      --modal-background: ${theme.colors.modalBackground};
      --modal-border: ${theme.colors.modalBorder};

      /** Page */
      --background: ${theme.colors.background};
      --article-box-border-radius: var(--border-radius-small);

      /** Topbar */
      --topbar-background: ${theme.colors.topBar};
      --topbar-border-bottom: solid 1px ${theme.colors.menuBorder};
      --topbar-height: 50px;

      --statbar-background: ${theme.colors.statBar};
      --statbar-border-bottom: solid 1px ${theme.colors.menuBorder};
      --statbar-height: 50px;

      --footer-border-top: solid 1px ${theme.colors.menuBorder};
      --footer-background: ${theme.colors.footer};

      --total-top-height: calc(var(--topbar-height) + var(--statbar-height));

      /** Borders */
      --border-radius-large: 12px;
      --border-radius-medium: 8px;
      --border-radius-small: 4px;
      --border-radius-rounded: 50%;
      --border-radius-pill: 1000px;

      /* Shadows */
      --focus-shadow: 0px 0px 0px 3px var(--primary-20);

      --depth-shadow-small: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
      --depth-shadow-medium: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
      --depth-shadow-large: 0px 0px 8px 0px rgba(0, 0, 0, 0.8);

      /* Transitions */
      --transition-extra-short: ease 80ms;
      --transition-short: ease 100ms;
      --transition-medium: ease 200ms;
      --transition-long: ease 300ms;
      --transition-extra-long: ease 400ms;

      /* Sizes */
      --size-minuscule: 2px;
      --size-extra-small: 4px;
      --size-small: 8px;
      --size-medium: 16px;
      --size-large: 24px;
      --size-extra-large: 32px;
      --size-gigantic: 40px;

      /* Typography */
      --font-color: ${theme.colors.font};

      --font-extra-minuscule: 8px;
      --font-minuscule: 12px;
      --font-extra-small: 14px;
      --font-small: 16px;
      --font-medium: 18px;
      --font-large: 20px;
      --font-extra-large: 22px;

      --font-heading-minuscule: 16px;
      --font-heading-extra-small: 18px;
      --font-heading-small: 20px;
      --font-heading-medium: 22px;
      --font-heading-large: 24px;
      --font-heading-extra-large: 26px;
      --font-heading-gigantic: 28px;

      --font-weight-thin: 200;
      --font-weight-light: 300;
      --font-weight-regular: 400;
      --font-weight-medium: 500;
      --font-weight-semi-bold: 600;
      --font-weight-bold: bold;
    `}
}
`
