import styled from 'styled-components'

export const ButtonSection = styled.div`
  display: flex;
  margin-top: var(--size-large);

  button:nth-child(1) {
    margin-right: var(--size-small);
  }
  button:nth-child(2) {
    margin-left: var(--size-small);
  }
`
