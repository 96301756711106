import { Button } from 'components/button/Button'
import { Container } from 'components/container/Container'
import { motion } from 'framer-motion'
import styled from 'styled-components'

export const ScrollTopContainer = styled(motion.div)`
  position: sticky;
  z-index: 11;
  display: flex;
  margin: 0px var(--size-medium);
  bottom: var(--size-medium);
  justify-content: flex-end;
`

export const ScrollTopButton = styled(Button)`
  border-radius: 50%;
  background: var(--neutral-400);
  border: none;
  color: var(--primary-0);
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    font-size: 32px;
  }
`

export const ScrollContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-padding-top: 108px;
`

export const TopMenuContainer = styled.div`
  position: fixed;
  transition: top 0.2s;
  top: 0;
  width: 100%;
  z-index: 10;
`

export const MainContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: var(--total-top-height);
  background-color: var(--background);
  box-sizing: border-box;
`

export const ToastTitle = styled.div`
  font-weight: bold;
  margin-bottom: var(--size-small);
`
