import axios from 'axios'
import { ArtilceResult } from 'types/article'

const WIKIPEDIA_API_URL =
  '/w/api.php?action=query&format=json&prop=pageprops|pageimages|description&generator=prefixsearch&redirects=1&formatversion=2&origin=*'

const getWikipediaURL = (query: string, languageCode: string) =>
  `https://${languageCode}.wikipedia.org` +
  WIKIPEDIA_API_URL +
  `&gpssearch=${query}`

interface QueryResponse {
  query: {
    pages: {
      title: string
      description: string
      pageprops: {
        wikibase_item: string
      }
      thumbnail: {
        source: string
      }
    }[]
  }
}

export const queryArticles = async (
  query: string,
  languageCode: string
): Promise<ArtilceResult[]> => {
  return axios
    .get<QueryResponse>(getWikipediaURL(query, languageCode), {
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    })
    .then((response) => {
      const results = response.data.query.pages
      const mapped = results.map((result) => ({
        id: result.pageprops.wikibase_item,
        title: result.title,
        description: result.description,
      }))

      return mapped
    })
}
