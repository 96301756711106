import { FormattedMessage, useIntl } from 'react-intl'
import { Label } from 'components/label/Label'
import { Modal } from 'components/modal/Modal'
import { LetsGoButton, Section } from './helpModal.styles'
import { languagesInfo } from 'constants/languageOptions'

type HelpModalProps = { onClose: () => void; isOpen: boolean }

export const HelpModal = ({ onClose, isOpen }: HelpModalProps) => {
  const intl = useIntl()

  return (
    <Modal
      isOpen={isOpen}
      title={
        '👋 ' +
        intl.formatMessage(
          {
            id: 'welcome',
            defaultMessage: 'Welcome to {gameName}!',
          },
          { gameName: 'linkdle' }
        )
      }
      subtitle={intl.formatMessage({
        id: 'how-to-play',
        defaultMessage: 'How to play?',
      })}
      onClose={onClose}
    >
      <Section>
        <Label>
          {intl.formatMessage({ id: 'goal', defaultMessage: 'Goal' })} 🎯
        </Label>
        <div>
          <FormattedMessage
            id={'goal-description'}
            defaultMessage={`The game objective is to reach the <i>target wikipedia article</i> 
            by only clicking on <i>links</i>, starting from another random article.`}
            values={{ i: (chunks) => <i>{chunks}</i> }}
          />
        </div>
      </Section>
      <Section>
        <Label>
          {intl.formatMessage({
            id: 'daily',
            defaultMessage: 'Daily Game',
          })}{' '}
          🗓
        </Label>
        <div>
          <FormattedMessage
            id={'daily-description-revised-3'}
            defaultMessage={`Both the <i>starting</i> and the <i>target article</i> will change every day. They
          are the same for everyone playing in the same language.`}
            values={{ i: (chunks) => <i>{chunks}</i> }}
          />
        </div>
      </Section>
      <Section>
        <Label>
          {intl.formatMessage({
            id: 'custom-challenge-2',
            defaultMessage: 'Create Custom Challenges',
          })}{' '}
          🧠
        </Label>
        <div>
          <FormattedMessage
            id={'custom-challenge-description'}
            defaultMessage={`Create custom challenges and compete with your friends!`}
          />
        </div>
      </Section>
      <Section>
        <Label>
          {intl.formatMessage({
            id: 'multi-language',
            defaultMessage: `Multi Language`,
          })}{' '}
          🌍
        </Label>
        <div>
          <FormattedMessage
            id={'supported-languages-revised'}
            defaultMessage={`{languageCount} languages are currently supported! Each
          language will have unique <i>starting</i> and <i>target articles</i> every day!
          You can change the current language using the settings menu.
          `}
            values={{
              languageCount: languagesInfo.length - 1,
              i: (chunks) => <i>{chunks}</i>,
            }}
          />
        </div>
      </Section>

      <div>
        <LetsGoButton onClick={onClose}>
          {intl.formatMessage({
            id: 'lets-go',
            defaultMessage: `Let's go!`,
          })}
        </LetsGoButton>
      </div>
    </Modal>
  )
}
