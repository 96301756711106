import axios from 'axios'
import { isMobile } from 'utils/isMobile'

const WIKIPEDIA_API_URL =
  '/w/api.php?action=parse&prop=text&format=json&redirects=1&origin=*&useskin=modern'

const getWikipediaURL = (page: string, languageCode: string) =>
  `https://${languageCode}.wikipedia.org` +
  WIKIPEDIA_API_URL +
  `&page=${page}` +
  (isMobile() ? `&mobileformat=1` : '')

export const fetchArticleContent = async (
  page: string,
  languageCode: string
) => {
  const response = await axios
    .get(getWikipediaURL(page, languageCode), {
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    })
    .then((response) => response.data)

  return { title: response.parse.title, html: response.parse.text['*'] }
}
