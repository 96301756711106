import Modal from 'react-modal'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { Analytics } from '@vercel/analytics/react'

// Constants
import { LANGUAGE_LOCAL_KEY } from 'components/game/game.constants'

// Styles
import { GlobalStyle } from 'app.styles'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toggle/style.css'

// Providers
import { ChangeThemeProvider } from 'providers/ChangeThemeProvider'

// Hooks
import { useLocalState } from 'hooks/useLocalState'
import { Game } from 'components/game/Game'
import { languagesInfo, LOCALE_EXCEPTIONS } from 'constants/languageOptions'
import { IntlProvider } from 'react-intl'
import messages from './lang'
import { ModalsProvider } from 'providers/ModalsProvider'

Modal.setAppElement('#root')

function App() {
  const [savedLanguage, setSavedLanguage] = useLocalState(
    LANGUAGE_LOCAL_KEY,
    'en'
  )

  return (
    <>
      <ChangeThemeProvider>
        <ModalsProvider>
          <Toaster />
          <GlobalStyle />
          <BrowserRouter>
            <Routes>
              {languagesInfo.map((lang) => {
                const langCode = lang.code
                const intlLocale = LOCALE_EXCEPTIONS[langCode] ?? langCode

                return (
                  <Route
                    key={langCode}
                    path={`/${langCode}/*`}
                    element={
                      <IntlProvider
                        locale={intlLocale}
                        messages={messages[intlLocale]}
                      >
                        <Game
                          urlLanguage={langCode}
                          savedLanguage={savedLanguage}
                          setSavedLanguage={setSavedLanguage}
                        />
                      </IntlProvider>
                    }
                  />
                )
              })}
              <Route path='*' element={<Navigate to={`/${savedLanguage}`} />} />
            </Routes>
          </BrowserRouter>
        </ModalsProvider>
      </ChangeThemeProvider>
      <Analytics />
    </>
  )
}

export default App
