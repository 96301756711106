import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Modal } from 'components/modal/Modal'
import { CreateStep } from './components/CreateStep'
import { ShareStep } from './components/ShareStep'
import { ShareContent } from 'types/shareContent'
import { BASE_URL } from 'constants/baseURL'
import { ArtilceResult } from 'types/article'

type CreateModalProps = {
  currentLanguage: string
  onClose: () => void
  isOpen: boolean
}

export const CreateModal = ({
  currentLanguage,
  onClose,
  isOpen,
}: CreateModalProps) => {
  const intl = useIntl()
  const [shareContent, setShareContent] = useState<ShareContent | undefined>()

  useEffect(() => {
    if (!isOpen) {
      setShareContent(undefined)
    }
  }, [isOpen])

  const createChallenge = (challenge: [ArtilceResult, ArtilceResult]) => {
    setShareContent({
      url: `${BASE_URL}/${currentLanguage}/wiki/${challenge[0].title.replaceAll(
        ' ',
        '_'
      )}?challenge=${challenge.map((article) => article.id).join('_')}`,
      text:
        intl.formatMessage(
          {
            id: 'share-message-custom-challenge',
            defaultMessage: 'Can you solve this {gameName}?',
          },
          {
            gameName: 'linkdle',
          }
        ) + ` (${challenge[0].title} → ${challenge[1].title})`,
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      title={`🧠 ${intl.formatMessage({
        id: 'create',
        defaultMessage: 'Create Challenge',
      })}`}
      onClose={onClose}
    >
      {shareContent ? (
        <ShareStep
          shareContent={shareContent}
          resetShareContent={() => {
            setShareContent(undefined)
          }}
        />
      ) : (
        <CreateStep
          onSubmit={createChallenge}
          currentLanguage={currentLanguage}
          onClose={onClose}
        />
      )}
    </Modal>
  )
}
