import axios from 'axios'
import seededRandom from 'seedrandom'

// Constants
import { topPagesByLanguage } from 'constants/topPagesByLanguage'

// Types
import { ArticleType } from 'types/article'
import { fetchFinalTitle } from './checkForRedirects'

const ARTICLE_TITLE_API = `https://www.wikidata.org/w/api.php?action=wbgetentities&format=json&props=sitelinks&origin=*`
const MAX_RETRIES = 4

type Response<T> = {
  success: boolean
  value?: T
}

export const getArticleTitle = async (
  id: string,
  language = 'enwiki'
): Promise<Response<ArticleType>> => {
  return axios
    .get(ARTICLE_TITLE_API + `&sitefilter=${language}&ids=${id}`)
    .then((response) => {
      try {
        const title = response.data.entities[id].sitelinks[language].title
        return {
          success: true,
          value: { title, titleURL: title.replaceAll(' ', '_') },
        }
      } catch (e) {
        return { success: false }
      }
    })
}

export const getArticleLanguages = async (
  id: string
): Promise<Record<string, any>> => {
  return axios.get(ARTICLE_TITLE_API + `&ids=${id}`).then((response) => {
    return response.data.entities[id].sitelinks
  })
}

const requestRandomArticles = async (
  langDbCode: string,
  seed: string,
  topPages: string[],
  currentLanguage: string,
  triesRemaining = MAX_RETRIES
): Promise<[ArticleType, ArticleType]> => {
  const random = seededRandom(seed)
  const startIndex = Math.floor(random() * topPages.length)
  const startArticle = topPages.splice(startIndex, 1)[0]
  const endArticle = topPages[Math.floor(random() * topPages.length)]

  const articles = [startArticle, endArticle]

  const titles = [
    getArticleTitle(startArticle, langDbCode),
    getArticleTitle(endArticle, langDbCode),
  ]
  const titleResponses = await Promise.all(titles)

  const failedArticles = titleResponses
    .filter((response) => !response.success)
    .map((_, index) => articles[index])

  if (!failedArticles.length) {
    const titles = titleResponses.map((response) => response.value) as [
      ArticleType,
      ArticleType
    ]

    const finalTitlePromises = [
      fetchFinalTitle(titles[0].title, currentLanguage),
      fetchFinalTitle(titles[1].title, currentLanguage),
    ] as [Promise<ArticleType>, Promise<ArticleType>]

    return Promise.all(finalTitlePromises)
  }

  const filteredTopPages = topPages.filter(
    (page) => !failedArticles.includes(page)
  )

  if (triesRemaining > 0) {
    return requestRandomArticles(
      langDbCode,
      seed,
      filteredTopPages,
      currentLanguage,
      triesRemaining - 1
    )
  }

  throw new Error('Something is wrong, maximum retries done :(')
}

export const getRandomArticles = async (
  langDbCode: string,
  seed: string,
  currentLanguage: string
): Promise<[ArticleType, ArticleType]> => {
  const topPages = JSON.parse(JSON.stringify(topPagesByLanguage[langDbCode]))

  return requestRandomArticles(langDbCode, seed, topPages, currentLanguage)
}
