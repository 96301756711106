import { useIntl } from 'react-intl'
import {
  Jumps,
  Left,
  TargetArticleAnchor,
  StatbarContainer,
  Win,
  LoadingBar,
  LoadingBarAccent,
  Separator,
  TargetArticle,
} from './statbar.styles'
import { ArticleType } from 'types/article'
import Skeleton from 'react-loading-skeleton'
import { AnimatePresence } from 'framer-motion'

interface StatbarProps {
  currentArticle: string
  currentLanguage: string
  startArticle?: ArticleType
  targetArticle?: ArticleType
  jumps: number
  hasWon: boolean
  isArticleLoading: boolean
  onYouWonClick: () => void
}

export const Statbar = ({
  currentArticle,
  currentLanguage,
  targetArticle,
  jumps,
  hasWon,
  isArticleLoading,
  onYouWonClick,
}: StatbarProps) => {
  const intl = useIntl()

  return (
    <StatbarContainer>
      <Left>
        <TargetArticle>
          🎯
          {targetArticle ? (
            <TargetArticleAnchor
              target={'_blank'}
              href={`https://${currentLanguage}.wikipedia.org/wiki/${targetArticle.titleURL}`}
            >
              {targetArticle.title}
            </TargetArticleAnchor>
          ) : (
            <Skeleton
              baseColor={'var(--neutral-200)'}
              highlightColor={'var(--neutral-30)'}
              width={60}
              style={{ marginLeft: 'var(--size-small)' }}
            />
          )}
        </TargetArticle>
        <Separator />
        {hasWon ? (
          <Win onClick={onYouWonClick}>
            {'🏆 '}
            {intl.formatMessage({ id: 'won', defaultMessage: 'Won!' })}
          </Win>
        ) : (
          <Jumps>
            {jumps}{' '}
            {intl.formatMessage({ id: 'jumps', defaultMessage: 'Jumps' })}
          </Jumps>
        )}
      </Left>

      <AnimatePresence>
        {isArticleLoading && (
          <LoadingBar
            transition={{ ease: 'easeInOut', duration: 0.2 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LoadingBarAccent />
          </LoadingBar>
        )}
      </AnimatePresence>
    </StatbarContainer>
  )
}
