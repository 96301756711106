import { useLocalState } from 'hooks/useLocalState'
import React, { useCallback, useContext } from 'react'

// Styled components Theme Provider
import { ThemeProvider } from 'styled-components'

// Themes
import { darkTheme, lightTheme } from 'themes'

export const ChangeThemeContext = React.createContext<{
  toggle: () => void
  isDarkMode: boolean
}>({
  toggle: () => {
    throw new Error('Not initialized')
  },
  isDarkMode: false,
})

interface ThemeProviderProps {
  children: React.ReactNode
}

const isOSInDarkMode = () => {
  return (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  )
}

const DARK_MODE_SETTINGS = 'IS_DARK_MODE'

export const ChangeThemeProvider = ({ children }: ThemeProviderProps) => {
  const [isDarkMode, setIsDarkMode] = useLocalState(
    DARK_MODE_SETTINGS,
    isOSInDarkMode()
  )

  const toggleTheme = useCallback(() => {
    setIsDarkMode((currentState) => !currentState)
  }, [setIsDarkMode])

  return (
    <ChangeThemeContext.Provider value={{ toggle: toggleTheme, isDarkMode }}>
      <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        {children}
      </ThemeProvider>
    </ChangeThemeContext.Provider>
  )
}

export const useThemes = () => useContext(ChangeThemeContext)
