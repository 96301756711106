import AsyncSelect from 'react-select/async'
import { useCallback } from 'react'
import { queryArticles } from 'services/queryArticles'
import {
  ControlProps,
  InputProps,
  MultiValue,
  OptionProps,
  SingleValue,
  StylesConfig,
  components,
} from 'react-select'
import { ArtilceResult } from 'types/article'
import {
  CustomOption,
  OptionDescription,
  OptionTitle,
} from './searchArticle.styles'
import { SELECT_STYLES, SELECT_THEME } from 'styles/select.styles'
import { useIntl } from 'react-intl'
import { Icon } from 'components/icon/Icon'

const Option = (props: OptionProps<ArtilceResult>) => {
  return (
    <components.Option {...props}>
      <CustomOption>
        <OptionTitle>{props.data.title}</OptionTitle>
        <OptionDescription>{props.data.description}</OptionDescription>
      </CustomOption>
    </components.Option>
  )
}

export const SearchArticle = ({
  placeholder,
  currentLanguage,
  onChange,
}: {
  placeholder: string
  currentLanguage: string
  onChange: (
    option: SingleValue<ArtilceResult> | MultiValue<ArtilceResult>
  ) => void
}) => {
  const intl = useIntl()
  const query = useCallback(
    (query: string) => queryArticles(query, currentLanguage),
    [currentLanguage]
  )

  return (
    <AsyncSelect
      noOptionsMessage={() =>
        intl.formatMessage({
          id: 'type-to-search',
          defaultMessage: 'Type to start searching',
        })
      }
      getOptionLabel={(option) => option.title}
      getOptionValue={(option) => option.id}
      components={{ Option }}
      onChange={onChange}
      placeholder={placeholder}
      menuPortalTarget={document.body}
      styles={SELECT_STYLES as StylesConfig<ArtilceResult>}
      theme={SELECT_THEME}
      loadOptions={query}
    />
  )
}
