import styled from 'styled-components'

export const MonkeyEmoji = styled.div`
  font-size: 100px;
`

export const SomethingWentWrong = styled.div``

export const ErrorComponentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: var(--font-large);
`

export const MessageContainer = styled.div`
  > * {
    margin-top: var(--size-extra-large);
  }
  max-width: 800px;
`

export const ImageContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DogImage = styled.img`
  margin-top: var(--size-large);
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border-radius: 50%;
  object-position: 0px -46px;
  object-fit: cover;
`
