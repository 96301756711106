import ReactToggle from 'react-toggle'
import { ToggleContainer, ToggleLabel } from './toggle.styles'

type ToggleProps = {
  id: string
  label: string
  defaultChecked?: boolean
  disabled?: boolean
  onChange?: () => void
}

export const Toggle = ({
  id,
  label,
  defaultChecked,
  onChange,
  disabled,
}: ToggleProps) => {
  return (
    <ToggleContainer>
      <ReactToggle
        disabled={disabled}
        className='custom-toggle'
        id={id}
        defaultChecked={defaultChecked}
        onChange={onChange}
      />
      <ToggleLabel disabled={disabled} htmlFor={id}>
        {label}
      </ToggleLabel>
    </ToggleContainer>
  )
}
