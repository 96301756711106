import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const CloseButton = styled.button`
  padding: 0px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 24px;
    color: var(--font-color);
  }
`

export const ModalTitle = styled.h2`
  margin: 0px;
  margin-right: var(--size-extra-large);
`

export const ModalSubtitle = styled.h3`
  margin-bottom: var(--size-large);
  font-size: var(--font-medium);
  font-weight: var(--font-weight-bold);
`

export const ModalTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px var(--neutral-30);
  margin-bottom: var(--size-medium);
  padding-bottom: var(--size-medium);
`

export const MODAL_STYLES: ReactModal.Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 10,
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxSizing: 'border-box',
    background: 'var(--modal-background)',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: 'var(--border-radius-small)',
    outline: 'none',
    padding: '20px',
    border: '4px solid var(--modal-border)',
    boxShadow: 'var(--depth-shadow-small)',
    zIndex: 12,
  },
}
