import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { useIntl } from 'react-intl'
import { ButtonSection } from './areYouSureModal.styles'

type AreYouSureModalProps = {
  onClose: () => void
  onRestart: () => void
  isOpen: boolean
}

export const AreYouSureModal = ({
  onClose,
  isOpen,
  onRestart,
}: AreYouSureModalProps) => {
  const intl = useIntl()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={intl.formatMessage({
        id: 'are-you-sure',
        defaultMessage: 'Are you sure you want to start over?',
      })}
    >
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        {intl.formatMessage({
          id: 'progress-deleted',
          defaultMessage:
            'The progress of your current session for this language will be lost.',
        })}
        <ButtonSection>
          <Button isSecondary onClick={onClose}>
            {intl.formatMessage({
              id: 'cancel',
              defaultMessage: 'Cancel',
            })}
          </Button>
          <Button
            onClick={() => {
              onClose()
              onRestart()
            }}
          >
            {intl.formatMessage({
              id: 'restart',
              defaultMessage: 'Restart',
            })}
          </Button>
        </ButtonSection>
      </div>
    </Modal>
  )
}
