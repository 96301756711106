import React, { useState } from 'react'
import {
  FormattedMessage,
  FormattedRelativeTime,
  IntlShape,
  useIntl,
} from 'react-intl'
import { Icon } from 'components/icon/Icon'
import {
  PathStep,
  SecondaryTitle,
  Stat,
  TryAgainSection,
  WinningPath,
} from './winModal.styles'
import { Label } from 'components/label/Label'
import { Modal } from 'components/modal/Modal'
import { Button } from 'components/button/Button'
import { getNextLinkdleDate } from 'utils/getDateId'
import { OverlayElement } from 'types/overlayElement'
import { Share } from 'components/share/Share'
import { BASE_URL } from 'constants/baseURL'

const getShareContent = (
  intl: IntlShape,
  languageCode: string,
  jumps: number,
  initial: string,
  target: string,
  challengeId?: string
) => ({
  text: challengeId
    ? intl.formatMessage(
        {
          id: 'share-message-custom-challenge-won-2',
          defaultMessage: `I solved a custom {gameName} {gameChallenge} in {jumpCount} jumps!`,
        },
        {
          gameName: 'linkdle',
          jumpCount: jumps,
          gameChallenge: `(${initial} → ${target})`,
        }
      )
    : intl.formatMessage(
        {
          id: 'share-message-revised-3',
          defaultMessage: `I solved today's {gameName} {gameChallenge} in {jumpCount} jumps!`,
        },
        {
          gameName: 'linkdle',
          jumpCount: jumps,
          gameChallenge: `(${initial} → ${target})`,
        }
      ) + ' 🎉',
  url: challengeId
    ? `${BASE_URL}/${languageCode}/wiki/${initial?.replaceAll(
        ' ',
        '_'
      )}?challenge=${challengeId}`
    : `${BASE_URL}/${languageCode}`,
})

interface WinModalProps {
  jumps: number
  winTime: number
  currentLanguageData: { code: string; name: string; dbname: string }
  winningPath: string[]
  onClose: () => void
  onTryAgain: () => void
  isOpen: boolean
  overlayElement: OverlayElement
  challengeId?: string
}

export const WinModal = ({
  challengeId,
  jumps,
  winTime,
  currentLanguageData,
  winningPath,
  onClose,
  onTryAgain,
  isOpen,
  overlayElement,
}: WinModalProps) => {
  const intl = useIntl()
  const isChallenge = !!challengeId

  const shareContent = getShareContent(
    intl,
    currentLanguageData.code,
    jumps,
    winningPath[0],
    winningPath[winningPath.length - 1],
    challengeId
  )
  const [nextLinkdleDate] = useState(() => getNextLinkdleDate())

  return (
    <Modal
      overlayElement={overlayElement}
      isOpen={isOpen}
      title={
        intl.formatMessage({
          id: 'you-won',
          defaultMessage: 'You have won!',
        }) + '🏅'
      }
      subtitle={intl.formatMessage({ id: 'stats', defaultMessage: 'Stats' })}
      onClose={onClose}
    >
      <Stat>
        <Label>
          {intl.formatMessage({
            id: 'jumps',
            defaultMessage: 'Jumps',
          })}{' '}
          🦘
        </Label>
        <div>{jumps}</div>
      </Stat>
      <Stat>
        <Label>
          {intl.formatMessage({
            id: 'time',
            defaultMessage: 'Time',
          })}{' '}
          ⏲️
        </Label>
        <div>
          <FormattedMessage
            id={'win-time'}
            defaultMessage={'{minCount} minutes and {secCount} seconds'}
            values={{
              secCount: parseInt(
                ((winTime - parseInt(winTime.toString())) * 60).toString()
              ),
              minCount: parseInt(winTime.toString()),
            }}
          />
        </div>
      </Stat>
      <div>
        <Label>
          {intl.formatMessage({
            id: 'path',
            defaultMessage: 'Path',
          })}{' '}
          🛣️
        </Label>
        <WinningPath>
          {winningPath.map((article, index) => (
            <React.Fragment key={article}>
              <PathStep>{article}</PathStep>
              {index + 1 < winningPath.length && (
                <Icon icon={'arrow_forward'} />
              )}
            </React.Fragment>
          ))}
        </WinningPath>
      </div>
      <TryAgainSection>
        <SecondaryTitle>
          {intl.formatMessage({
            id: 'can-do-better',
            defaultMessage: 'Can you do better?',
          })}
        </SecondaryTitle>
        <Button onClick={onTryAgain}>
          {intl.formatMessage({
            id: 'try-again',
            defaultMessage: 'Try again!',
          })}
        </Button>
      </TryAgainSection>
      {!isChallenge && (
        <Stat>
          ⌛️{' '}
          <i>
            {intl.formatMessage(
              {
                defaultMessage: 'Next {gameName}',
                id: 'next-linkdle',
              },
              { gameName: 'linkdle' }
            )}{' '}
            <FormattedRelativeTime
              numeric='auto'
              value={nextLinkdleDate}
              updateIntervalInSeconds={1}
            />
          </i>{' '}
          ⏳
        </Stat>
      )}
      <SecondaryTitle>
        {intl.formatMessage({
          id: 'share-results',
          defaultMessage: 'Share your results!',
        })}
      </SecondaryTitle>
      <Share shareContent={shareContent} />
    </Modal>
  )
}
