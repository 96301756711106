import React, { useContext } from 'react'
import { INITIAL_LOCAL_STATE } from './game.constants'
import { GameProperty, LocalState } from './game.types'

const GameContext = React.createContext<
  [LocalState, (key: GameProperty, value: unknown) => void]
>([
  INITIAL_LOCAL_STATE,
  () => {
    throw new Error('Context not initialized')
  },
])

export const GameContextProvider = GameContext.Provider
export const useGameContext = () => useContext(GameContext)
