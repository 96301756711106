import {
  Logo,
  LogoIcon,
  Right,
  TopBarButton,
  TopbarContainer,
} from './topbar.styles'
import { Icon } from 'components/icon/Icon'
import { useModals } from 'providers/ModalsProvider'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

interface TopbarProps {
  currentLanguage: string
}

export const Topbar = ({ currentLanguage }: TopbarProps) => {
  const intl = useIntl()
  const { areYouSure, howToPlay, settings, create } = useModals()

  return (
    <TopbarContainer>
      <Link to={`/${currentLanguage}`}>
        <Logo>
          <LogoIcon src={'/icons/kangaroo.svg'} title={'logo'} />
          <span>linkdle</span>
        </Logo>
      </Link>
      <Right>
        <TopBarButton
          onClick={create.openModal}
          title={intl.formatMessage({
            id: 'create',
            defaultMessage: 'Create Challenge',
          })}
        >
          <Icon icon='add_circle' />
        </TopBarButton>
        <TopBarButton
          onClick={areYouSure.openModal}
          title={intl.formatMessage({
            id: 'restart',
            defaultMessage: 'Restart',
          })}
        >
          <Icon icon='change_circle' />
        </TopBarButton>
        <TopBarButton
          onClick={howToPlay.openModal}
          title={intl.formatMessage({
            id: 'how-to-play',
            defaultMessage: 'How to play?',
          })}
        >
          <Icon icon='help' />
        </TopBarButton>
        <TopBarButton
          onClick={settings.openModal}
          title={intl.formatMessage({
            id: 'settings',
            defaultMessage: 'Settings',
          })}
        >
          <Icon icon='settings' />
        </TopBarButton>
      </Right>
    </TopbarContainer>
  )
}
