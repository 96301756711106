import { IntlShape } from 'react-intl'

export type LanguageOption = {
  label: string
  value: {
    dbname: string
    code: string
  }
}

export const getLanguageOptions = (intl: IntlShape): LanguageOption[] => {
  const translationDict = getTranslatedLanguages(intl)

  return Object.values(languagesInfo).map((info) => {
    const label = translationDict[info.code]

    return {
      label: label.charAt(0).toUpperCase() + label.slice(1),
      value: info,
    }
  })
}

const getTranslatedLanguages = (intl: IntlShape): Record<string, string> => ({
  af: intl.formatMessage({ defaultMessage: 'Afrikaans', id: 'af' }),
  ar: intl.formatMessage({ defaultMessage: 'Arabic', id: 'ar' }),
  hy: intl.formatMessage({ defaultMessage: 'Armenian', id: 'hy' }),
  az: intl.formatMessage({ defaultMessage: 'Azerbaijani', id: 'az' }),
  bn: intl.formatMessage({ defaultMessage: 'Bangla', id: 'bn' }),
  eu: intl.formatMessage({ defaultMessage: 'Basque', id: 'eu' }),
  be: intl.formatMessage({ defaultMessage: 'Belarusian', id: 'be' }),
  bs: intl.formatMessage({ defaultMessage: 'Bosnian', id: 'bs' }),
  bg: intl.formatMessage({ defaultMessage: 'Bulgarian', id: 'bg' }),
  ca: intl.formatMessage({ defaultMessage: 'Catalan', id: 'ca' }),
  zh: intl.formatMessage({ defaultMessage: 'Chinese', id: 'zh' }),
  hr: intl.formatMessage({ defaultMessage: 'Croatian', id: 'hr' }),
  cs: intl.formatMessage({ defaultMessage: 'Czech', id: 'cs' }),
  da: intl.formatMessage({ defaultMessage: 'Danish', id: 'da' }),
  nl: intl.formatMessage({ defaultMessage: 'Dutch', id: 'nl' }),
  en: intl.formatMessage({ defaultMessage: 'English', id: 'en' }),
  eo: intl.formatMessage({ defaultMessage: 'Esperanto', id: 'eo' }),
  et: intl.formatMessage({ defaultMessage: 'Estonian', id: 'et' }),
  fi: intl.formatMessage({ defaultMessage: 'Finnish', id: 'fi' }),
  fr: intl.formatMessage({ defaultMessage: 'French', id: 'fr' }),
  gl: intl.formatMessage({ defaultMessage: 'Galician', id: 'gl' }),
  ka: intl.formatMessage({ defaultMessage: 'Georgian', id: 'ka' }),
  de: intl.formatMessage({ defaultMessage: 'German', id: 'de' }),
  el: intl.formatMessage({ defaultMessage: 'Greek', id: 'el' }),
  he: intl.formatMessage({ defaultMessage: 'Hebrew', id: 'he' }),
  hi: intl.formatMessage({ defaultMessage: 'Hindi', id: 'hi' }),
  hu: intl.formatMessage({ defaultMessage: 'Hungarian', id: 'hu' }),
  is: intl.formatMessage({ defaultMessage: 'Icelandic', id: 'is' }),
  id: intl.formatMessage({ defaultMessage: 'Indonesian', id: 'id' }),
  ga: intl.formatMessage({ defaultMessage: 'Irish', id: 'ga' }),
  it: intl.formatMessage({ defaultMessage: 'Italian', id: 'it' }),
  ja: intl.formatMessage({ defaultMessage: 'Japanese', id: 'ja' }),
  kk: intl.formatMessage({ defaultMessage: 'Kazakh', id: 'kk' }),
  ko: intl.formatMessage({ defaultMessage: 'Korean', id: 'ko' }),
  la: intl.formatMessage({ defaultMessage: 'Latin', id: 'la' }),
  lv: intl.formatMessage({ defaultMessage: 'Latvian', id: 'lv' }),
  lt: intl.formatMessage({ defaultMessage: 'Lithuanian', id: 'lt' }),
  mk: intl.formatMessage({ defaultMessage: 'Macedonian', id: 'mk' }),
  ms: intl.formatMessage({ defaultMessage: 'Malay', id: 'ms' }),
  ml: intl.formatMessage({ defaultMessage: 'Malayalam', id: 'ml' }),
  no: intl.formatMessage({ defaultMessage: 'Norwegian', id: 'no' }),
  fa: intl.formatMessage({ defaultMessage: 'Persian', id: 'fa' }),
  pl: intl.formatMessage({ defaultMessage: 'Polish', id: 'pl' }),
  pt: intl.formatMessage({ defaultMessage: 'Portuguese', id: 'pt' }),
  pa: intl.formatMessage({ defaultMessage: 'Punjabi', id: 'pa' }),
  ro: intl.formatMessage({ defaultMessage: 'Romanian', id: 'ro' }),
  ru: intl.formatMessage({ defaultMessage: 'Russian', id: 'ru' }),
  sr: intl.formatMessage({ defaultMessage: 'Serbian', id: 'sr' }),
  sh: intl.formatMessage({ defaultMessage: 'Serbo-Croatian', id: 'sh' }),
  simple: intl.formatMessage({
    defaultMessage: 'Simple English',
    id: 'simple',
  }),
  sk: intl.formatMessage({ defaultMessage: 'Slovak', id: 'sk' }),
  sl: intl.formatMessage({ defaultMessage: 'Slovenian', id: 'sl' }),
  es: intl.formatMessage({ defaultMessage: 'Spanish', id: 'es' }),
  sv: intl.formatMessage({ defaultMessage: 'Swedish', id: 'sv' }),
  tl: intl.formatMessage({ defaultMessage: 'Tagalog', id: 'tl' }),
  ta: intl.formatMessage({ defaultMessage: 'Tamil', id: 'ta' }),
  th: intl.formatMessage({ defaultMessage: 'Thai', id: 'th' }),
  tr: intl.formatMessage({ defaultMessage: 'Turkish', id: 'tr' }),
  uk: intl.formatMessage({ defaultMessage: 'Ukrainian', id: 'uk' }),
  ur: intl.formatMessage({ defaultMessage: 'Urdu', id: 'ur' }),
  uz: intl.formatMessage({ defaultMessage: 'Uzbek', id: 'uz' }),
  vi: intl.formatMessage({ defaultMessage: 'Vietnamese', id: 'vi' }),
  cy: intl.formatMessage({ defaultMessage: 'Welsh', id: 'cy' }),
})

export const languagesInfo = [
  { dbname: 'afwiki', code: 'af' },
  { dbname: 'arwiki', code: 'ar' },
  { dbname: 'hywiki', code: 'hy' },
  { dbname: 'azwiki', code: 'az' },
  { dbname: 'bnwiki', code: 'bn' },
  { dbname: 'euwiki', code: 'eu' },
  { dbname: 'bewiki', code: 'be' },
  { dbname: 'bswiki', code: 'bs' },
  { dbname: 'bgwiki', code: 'bg' },
  { dbname: 'cawiki', code: 'ca' },
  { dbname: 'zhwiki', code: 'zh' },
  { dbname: 'hrwiki', code: 'hr' },
  { dbname: 'cswiki', code: 'cs' },
  { dbname: 'dawiki', code: 'da' },
  { dbname: 'nlwiki', code: 'nl' },
  { dbname: 'enwiki', code: 'en' },
  { dbname: 'eowiki', code: 'eo' },
  { dbname: 'etwiki', code: 'et' },
  { dbname: 'fiwiki', code: 'fi' },
  { dbname: 'frwiki', code: 'fr' },
  { dbname: 'glwiki', code: 'gl' },
  { dbname: 'kawiki', code: 'ka' },
  { dbname: 'dewiki', code: 'de' },
  { dbname: 'elwiki', code: 'el' },
  { dbname: 'hewiki', code: 'he' },
  { dbname: 'hiwiki', code: 'hi' },
  { dbname: 'huwiki', code: 'hu' },
  { dbname: 'iswiki', code: 'is' },
  { dbname: 'idwiki', code: 'id' },
  { dbname: 'gawiki', code: 'ga' },
  { dbname: 'itwiki', code: 'it' },
  { dbname: 'jawiki', code: 'ja' },
  { dbname: 'kkwiki', code: 'kk' },
  { dbname: 'kowiki', code: 'ko' },
  { dbname: 'lawiki', code: 'la' },
  { dbname: 'lvwiki', code: 'lv' },
  { dbname: 'ltwiki', code: 'lt' },
  { dbname: 'mkwiki', code: 'mk' },
  { dbname: 'mswiki', code: 'ms' },
  { dbname: 'mlwiki', code: 'ml' },
  { dbname: 'nowiki', code: 'no' },
  { dbname: 'fawiki', code: 'fa' },
  { dbname: 'plwiki', code: 'pl' },
  { dbname: 'ptwiki', code: 'pt' },
  { dbname: 'pawiki', code: 'pa' },
  { dbname: 'rowiki', code: 'ro' },
  { dbname: 'ruwiki', code: 'ru' },
  { dbname: 'srwiki', code: 'sr' },
  { dbname: 'shwiki', code: 'sh' },
  { dbname: 'simplewiki', code: 'simple' },
  { dbname: 'skwiki', code: 'sk' },
  { dbname: 'slwiki', code: 'sl' },
  { dbname: 'eswiki', code: 'es' },
  { dbname: 'svwiki', code: 'sv' },
  { dbname: 'tlwiki', code: 'tl' },
  { dbname: 'tawiki', code: 'ta' },
  { dbname: 'thwiki', code: 'th' },
  { dbname: 'trwiki', code: 'tr' },
  { dbname: 'ukwiki', code: 'uk' },
  { dbname: 'urwiki', code: 'ur' },
  { dbname: 'uzwiki', code: 'uz' },
  { dbname: 'viwiki', code: 'vi' },
  { dbname: 'cywiki', code: 'cy' },
]

export const LOCALE_EXCEPTIONS: Record<string, string> = {
  simple: 'en',
  'zh-yue': 'yue',
}
