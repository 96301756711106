import { Label } from 'components/label/Label'
import styled from 'styled-components'

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;

  .custom-toggle.react-toggle--checked .react-toggle-track {
    background-color: var(--primary-10);
  }

  .react-toggle--focus .react-toggle-thumb {
    box-shadow: 0px 0px 2px 3px var(--primary-0);
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: 0px 0px 5px 5px var(--primary-10);
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: var(--primary-10);
  }
`

export const ToggleLabel = styled(Label)`
  margin: 0px;
  margin-left: var(--size-small);
`
