import styled, { css } from 'styled-components'

type LabelProps = {
  disabled?: boolean
}

export const Label = styled.label<LabelProps>`
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--size-small);

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--neutral-300);
    `}
`
