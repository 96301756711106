import axios from 'axios'

const WIKIPEDIA_API_URL =
  '/w/api.php?format=json&action=query&list=categorymembers&origin=*&cmlimit=50&redirects=1'

const getWikipediaURL = (page: string, languageCode: string) =>
  `https://${languageCode}.wikipedia.org` +
  WIKIPEDIA_API_URL +
  `&cmtitle=${page}`

interface CategoryMembersResponse {
  query: {
    categorymembers?: { title: string }[]
  }
}

export const fetchArticlesOfCategory = async (
  page: string,
  languageCode: string
): Promise<string[]> => {
  try {
    return await axios
      .get<CategoryMembersResponse>(getWikipediaURL(page, languageCode), {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
      .then((response) => {
        const categorymembers = response.data.query?.categorymembers

        return categorymembers
          ? categorymembers.map((member) => member.title)
          : []
      })
  } catch (e) {
    console.error(e)
    return []
  }
}
