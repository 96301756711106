import { Button } from 'components/button/Button'
import { Share } from 'components/share/Share'
import { useIntl } from 'react-intl'
import { ShareContent } from 'types/shareContent'
import { FormElement } from '../../../../formElement/FormElement'
import { SecondaryTitle } from '../../../winModal/winModal.styles'
import { ButtonRow } from '../createModal.styles'

export const ShareStep = ({
  shareContent,
  resetShareContent,
}: {
  shareContent: ShareContent
  resetShareContent: () => void
}) => {
  const intl = useIntl()

  return (
    <>
      <SecondaryTitle>
        {intl.formatMessage({
          id: 'created-success',
          defaultMessage: 'Successfully created',
        }) + ' 🎉'}
      </SecondaryTitle>
      <FormElement>
        {intl.formatMessage({
          id: 'share-challenge-text',
          defaultMessage: 'Share it so others can play it!',
        })}
      </FormElement>

      <FormElement>
        <Share shareContent={shareContent} />
      </FormElement>
      <ButtonRow>
        <Button isSecondary onClick={resetShareContent}>
          {intl.formatMessage({
            id: 'create-another-button',
            defaultMessage: 'Create another',
          })}
        </Button>
        <Button
          onClick={() => {
            const newWindow = window.open(shareContent.url, '_blank')

            if (newWindow) {
              newWindow.focus()
            }
          }}
        >
          {intl.formatMessage({
            id: 'play-button-2',
            defaultMessage: 'Play',
          })}
        </Button>
      </ButtonRow>
    </>
  )
}
