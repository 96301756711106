import { Button } from 'components/button/Button'
import { Container } from 'components/container/Container'
import styled from 'styled-components'

export const Author = styled.div`
  font-size: var(--font-minuscule);
  margin-top: var(--size-large);
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    text-align: center;
  }
`

export const BeerIcon = styled.div`
  @keyframes beerAnimation {
    0% {
      transform: scale(1.2) rotate(0deg);
    }

    25% {
      transform: scale(1.2) rotate(10deg);
    }

    50% {
      transform: scale(1.2) rotate(0deg);
    }

    75% {
      transform: scale(1.2) rotate(-10deg);
    }

    100% {
      transform: scale(1.2) rotate(0deg);
    }
  }
  margin-left: var(--size-small);
  animation-name: beerAnimation;
  animation-duration: 4s;
  animation-iteration-count: infinite;
`

export const HeartEmoji = styled.div`
  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.15);
    }
    50% {
      transform: scale(1);
    }
    60% {
      transform: scale(1.15);
    }
    100% {
      transform: scale(1);
    }
  }

  animation-name: heartbeat;
  animation-duration: 2s;
  animation-iteration-count: infinite;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    ${Button} {
      margin-top: var(--size-small);
    }
  }
`

export const FooterSection = styled(Container)`
  border-top: var(--footer-border-top);
  box-sizing: border-box;
  color: white;
  background: var(--footer-background);
  margin-top: var(--size-medium);
  padding-top: var(--size-large);
  padding-bottom: var(--size-small);

  a {
    color: #c2b6ff !important;
    cursor: pointer !important;
  }
`

export const Left = styled.div`
  color: inherit;
  display: flex;
  > * {
    margin: 0px var(--size-small);
  }
`
export const Right = styled.div`
  color: inherit;
`

export const WikipediaDonation = styled.div`
  margin-top: var(--size-small);
  font-size: var(--font-extra-small);
`
