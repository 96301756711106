import { DefaultTheme } from 'styled-components'

export const darkTheme: DefaultTheme = {
  colors: {
    buttonBorder: {
      primary: 'solid 1px #a795ff',
      secondary: 'solid 1px #f8baff',
    },
    buttonBackground: {
      primary: 'linear-gradient(90deg, #f8baff 0%, #c2b6ff 100%)',
      secondary: '#f3ddf5',
    },
    modalBackground: 'var(--neutral-100)',
    modalBorder: 'rgb(82 82 82)',
    topBar: 'var(--neutral-400)',
    statBar: 'var(--neutral-100)',
    footer: 'var(--neutral-400)',
    font: 'white',
    background: 'var(--neutral-0)',
    menuBorder: ' var(--neutral-300)',
    neutrals: {
      0: '#0a0a0a',
      10: '#1b1b1b',
      20: '#353535',
      30: '#5f5f5f',
      100: '#191919',
      200: '#302f2f',
      300: '#3d3d3d',
      400: '#2a2a2a',
    },
    primary: {
      0: '#907cf9',
      10: '#a795ff',
      20: '#c2b6ff',
      30: '',
      100: '',
      200: '',
      300: '',
      400: '',
    },
    secondary: {
      0: '#f173ff',
      10: '',
      20: '',
      30: '',
      100: '',
      200: '',
      300: '',
      400: '',
    },
  },
}
