import styled from 'styled-components'

export const ButtonRow = styled.div`
  display: flex;
  gap: var(--size-medium);
  margin-bottom: var(--size-medium);

  @media (max-width: 576px) {
    flex-direction: column-reverse;
  }
`
