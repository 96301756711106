import { Icon } from 'components/icon/Icon'
import React from 'react'
import ReactModal from 'react-modal'
import { OverlayElement } from 'types/overlayElement'
import {
  CloseButton,
  ModalContainer,
  ModalSubtitle,
  ModalTitle,
  ModalTitleRow,
  MODAL_STYLES,
} from './modal.styles'

type ModalProps = {
  title: string
  subtitle?: string
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  overlayElement?: OverlayElement
}

export const Modal = ({
  isOpen,
  title,
  subtitle,
  onClose,
  children,
  overlayElement,
}: ModalProps) => {
  return (
    <ReactModal
      closeTimeoutMS={200}
      className={'responsive-modal'}
      style={MODAL_STYLES}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayElement={overlayElement}
    >
      <ModalContainer>
        <ModalTitleRow>
          <ModalTitle>{title}</ModalTitle>
          <CloseButton onClick={onClose}>
            <Icon icon='close' />
          </CloseButton>
        </ModalTitleRow>
        {subtitle && <ModalSubtitle>{subtitle}</ModalSubtitle>}
        {children}
      </ModalContainer>
    </ReactModal>
  )
}
