import React, { useContext, useRef, useState } from 'react'
import {
  ANIMATIONS_DISABLED,
  ANNOUNCEMENT_KEY,
  HOW_TO_PLAY_KEY,
} from 'components/game/game.constants'
import { useLocalState } from 'hooks/useLocalState'

type ModalController = {
  isOpen: boolean
  closeModal: () => void
  openModal: () => void
}

const DEFAULT_MODAL_CONTROLLER: ModalController = {
  isOpen: false,
  closeModal: () => {
    throw Error('Closed before initialization')
  },
  openModal: () => {
    throw Error('Opened before initialization')
  },
}

export const ModalContext = React.createContext<{
  howToPlay: ModalController
  announcement: ModalController
  areYouSure: ModalController
  create: ModalController
  settings: ModalController
  howToPlaySeen: boolean
  announcementSeen: boolean
  firstTimePlaying: boolean
  animationsDisabled: boolean
  setAnimationsDisabled: (
    newState: boolean | ((currentState: boolean) => boolean)
  ) => void
}>({
  howToPlay: DEFAULT_MODAL_CONTROLLER,
  announcement: DEFAULT_MODAL_CONTROLLER,
  areYouSure: DEFAULT_MODAL_CONTROLLER,
  create: DEFAULT_MODAL_CONTROLLER,
  settings: DEFAULT_MODAL_CONTROLLER,
  howToPlaySeen: false,
  announcementSeen: false,
  firstTimePlaying: false,
  animationsDisabled: false,
  setAnimationsDisabled: () => {
    throw Error('Not initialized')
  },
})

interface ThemeProviderProps {
  children: React.ReactNode
}

export const ModalsProvider = ({ children }: ThemeProviderProps) => {
  const [isHowToPlayOpen, setIsHowToPlayOpen] = useState(false)
  const [isAreYouSureOpen, setAreYouSureOpen] = useState(false)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [areSettingsOpen, setAreSettingsOpen] = useState(false)
  const [announcementSeen, seAnnouncementSeen] = useLocalState(
    ANNOUNCEMENT_KEY,
    false
  )
  const [howToPlaySeen, setHowToPlaySeen] = useLocalState(
    HOW_TO_PLAY_KEY,
    false
  )
  const [animationsDisabled, setAnimationsDisabled] = useLocalState(
    ANIMATIONS_DISABLED,
    false
  )
  const firstTimePlaying = useRef(!howToPlaySeen)

  const closeHowToPlay = () => {
    setHowToPlaySeen(true)
    setIsHowToPlayOpen(false)
  }

  return (
    <ModalContext.Provider
      value={{
        announcementSeen,
        howToPlaySeen,
        setAnimationsDisabled,
        animationsDisabled: animationsDisabled,
        firstTimePlaying: firstTimePlaying.current,
        settings: {
          isOpen: areSettingsOpen,
          closeModal: () => setAreSettingsOpen(false),
          openModal: () => setAreSettingsOpen(true),
        },
        announcement: {
          isOpen: false,
          closeModal: () => seAnnouncementSeen(true),
          openModal: () => {
            throw new Error('Announcement cannot be opened')
          },
        },
        areYouSure: {
          isOpen: isAreYouSureOpen,
          closeModal: () => setAreYouSureOpen(false),
          openModal: () => setAreYouSureOpen(true),
        },
        howToPlay: {
          isOpen: isHowToPlayOpen || !howToPlaySeen,
          closeModal: closeHowToPlay,
          openModal: () => setIsHowToPlayOpen(true),
        },
        create: {
          isOpen: isCreateModalOpen,
          closeModal: () => setIsCreateModalOpen(false),
          openModal: () => setIsCreateModalOpen(true),
        },
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export const useModals = () => useContext(ModalContext)
