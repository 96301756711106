import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from 'components/button/Button'
import {
  Author,
  BeerIcon,
  FooterSection,
  HeartEmoji,
  Left,
  Right,
  Row,
  WikipediaDonation,
} from './footer.styles'
import { Heart } from 'components/heart/Heart'

export const Footer = () => {
  const intl = useIntl()

  return (
    <FooterSection>
      <Row>
        <Left>
          <FormattedMessage
            id={'made-with-love'}
            defaultMessage={
              'Made with <heart>❤️</heart> by <a>{authorName}</a>'
            }
            values={{
              authorName: '@hm4rkus',
              heart: () => <Heart />,
              a: (chunks) => (
                <a
                  target={'_blank'}
                  rel='noreferrer'
                  href='https://mobile.twitter.com/hm4rkus'
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </Left>
        <Right>
          <Button
            onClick={() => {
              window.open('https://buymeacoffee.com/hm4rkus', '_blank')
            }}
          >
            {intl.formatMessage({
              id: 'buy-me-beer',
              defaultMessage: 'Buy me a beer!',
            })}{' '}
            <BeerIcon>🍺</BeerIcon>
          </Button>
          <WikipediaDonation>
            <FormattedMessage
              id='also-consider'
              defaultMessage={'Also consider <a>donating to Wikipedia</a>'}
              values={{
                a: (chunks) => (
                  <a
                    target={'_blank'}
                    rel='noreferrer'
                    href='https://donate.wikimedia.org/w/index.php?title=Special:LandingPage&country=ES&uselang=es&utm_medium=spontaneous&utm_source=fr-redir&utm_campaign=spontaneous'
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </WikipediaDonation>
        </Right>
      </Row>
      <Author>
        © 2022 Marc García-Penche{' '}
        <a
          target={'_blank'}
          rel='noreferrer'
          href='https://mobile.twitter.com/linkdle'
        >
          @linkdle
        </a>
      </Author>
    </FooterSection>
  )
}
