import axios from 'axios'
import { ArticleType } from 'types/article'

const WIKIPEDIA_API_URL =
  '/w/api.php?format=json&action=query&origin=*&redirects=1'

const getWikipediaURL = (page: string, languageCode: string) =>
  `https://${languageCode}.wikipedia.org` +
  WIKIPEDIA_API_URL +
  `&titles=${page}`

interface TitleResponse {
  query: {
    pages: Record<string, ArticleType>
  }
}

export const fetchFinalTitle = async (
  page: string,
  languageCode: string
): Promise<ArticleType> => {
  return axios
    .get<TitleResponse>(getWikipediaURL(page, languageCode), {
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    })
    .then((response) => {
      const returnValue = Object.values(response.data.query.pages)[0]
      returnValue.titleURL = returnValue.title.replaceAll(' ', '_')
      return returnValue
    })
}
