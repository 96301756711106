import styled from 'styled-components'

export const HeartEmoji = styled.div`
  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.15);
    }
    50% {
      transform: scale(1);
    }
    60% {
      transform: scale(1.15);
    }
    100% {
      transform: scale(1);
    }
  }

  animation-name: heartbeat;
  animation-duration: 2s;
  animation-iteration-count: infinite;
`
