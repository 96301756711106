import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { fetchArticleContent, getRandomArticles } from 'services'
import { Article } from 'components/article/Article'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { Topbar } from './components/topbar/Topbar'
import {
  MainContainer,
  ScrollContainer,
  ScrollTopButton,
  ScrollTopContainer,
  TopMenuContainer,
} from './game.styles'
import {
  GAME_PROPERTIES,
  INITIAL_ARTICLE_CONTENT,
  INITIAL_GAME_STATE,
  SECOND_LEVEL_ROUTE,
} from './game.constants'
import { WinModal } from './components/modals/winModal/WinModal'
import { Statbar } from './components/statbar/Statbar'
import { FullScreenSpinner } from 'components/spinner/SpinnerComponent'
import {
  clearLanguageLocalStorageState,
  getInitialLocalStorageState,
  getWinningPath,
  navigateArticle,
  setLocalStorageState,
} from './game.utils'
import { GameContextProvider } from './GameContext'
import { GameProperty, GameState } from './game.types'
import ReactConfetti from 'react-confetti'
import { useWindowSize } from 'react-use'
import { getDateId } from 'utils/getDateId'
import { getLanguageOptions, languagesInfo } from 'constants/languageOptions'
import { Helmet } from 'react-helmet'
import { ErrorComponent } from 'components/error/ErrorComponent'
import { Footer } from 'components/footer/Footer'
import { fetchFinalTitle } from 'services/checkForRedirects'
import { getArticleTitle } from 'services/getRandomArticles'
import { Icon } from 'components/icon/Icon'
import { AnimatePresence } from 'framer-motion'
import { getItem } from 'utils/storage'
import { Modals } from './components/modals/Modals'
import { useModals } from 'providers/ModalsProvider'
import { fetchArticleCategories } from 'services/getArticleCategories'
import { fetchSubcategoriesOfCategory } from 'services/getSubcategoriesOfCategory'
import { fetchArticlesOfCategory } from 'services/getArticlesOfCategory'

interface GameProps {
  savedLanguage: string
  setSavedLanguage: (lang: string) => void
  urlLanguage: string
}

export const Game = ({
  savedLanguage,
  setSavedLanguage,
  urlLanguage,
}: GameProps) => {
  const [params] = useSearchParams('')
  const navigate = useNavigate()
  const { firstTimePlaying, howToPlaySeen } = useModals()
  const intl = useIntl()
  const contentRef = useRef<HTMLDivElement | null>(null)

  const challengeId = useMemo(() => params.get('challenge') || '', [params])
  const challenge: [string, string] | undefined = useMemo(
    () => challengeId?.split('_') as [string, string],
    [challengeId]
  )

  const { width, height } = useWindowSize()

  const scrollTop = useCallback(() => {
    contentRef.current?.scrollTo({ top: 0 })
  }, [])

  const scrollTopSmooth = useCallback(() => {
    contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const currentLanguage = useMemo(
    () => urlLanguage ?? savedLanguage,
    [urlLanguage, savedLanguage]
  )

  const [gameState, setGameState] = useState(INITIAL_GAME_STATE)
  const [articleContent, setArticleContent] = useState(INITIAL_ARTICLE_CONTENT)
  const [scrollTopShown, setScrollTopShown] = useState(false)
  const [localState, setLocalState] = useState(() =>
    getInitialLocalStorageState(currentLanguage, challengeId)
  )

  const setGameProperty = useCallback(
    <T,>(key: keyof GameState, newValue: T) => {
      setGameState((currentState) => ({ ...currentState, [key]: newValue }))
    },
    [setGameState]
  )

  const fetchContent = useCallback(
    async (articleName: string, currentLanguage: string) => {
      setGameProperty('isLoading', true)
      setGameProperty('isArticleLoading', true)
      const decodedArticle = decodeURI(articleName.replaceAll(' ', '_'))

      // In case of redirects.
      const contentPromise = fetchArticleContent(
        decodedArticle,
        currentLanguage
      )
      const categoriesPromise = fetchArticleCategories(
        decodedArticle,
        currentLanguage
      )
      const subcategoriesOfCategoryPromise = fetchSubcategoriesOfCategory(
        decodedArticle,
        currentLanguage
      )
      const articlesOfCategoryPromise = fetchArticlesOfCategory(
        decodedArticle,
        currentLanguage
      )
      const finalArticlePromise = fetchFinalTitle(
        decodedArticle,
        currentLanguage
      ).then((response) => response.titleURL)

      const [
        content,
        categories,
        subcategories,
        articlesOfCategory,
        finalArticle,
      ] = await Promise.all([
        contentPromise,
        categoriesPromise,
        subcategoriesOfCategoryPromise,
        articlesOfCategoryPromise,
        finalArticlePromise,
      ])

      setArticleContent({
        content,
        categories,
        subcategories,
        articlesOfCategory,
      })
      setGameProperty('isLoading', false)

      return finalArticle
    },
    [setGameProperty]
  )

  const onTryAgain = () => {
    clearLanguageLocalStorageState(currentLanguage, challengeId)
    setLocalState(getInitialLocalStorageState(currentLanguage, challengeId))
    setGameState(INITIAL_GAME_STATE)
    initialize()
  }

  const languageOptions = useMemo(() => getLanguageOptions(intl), [intl])

  const onError = useCallback((e: Error) => {
    setGameState((currentState) => ({
      ...currentState,
      isArticleLoading: false,
      isLoading: false,
      error: true,
    }))
    console.error(e)
  }, [])

  const currentLanguageData = useMemo(() => {
    let lang = languageOptions.find(
      (lang) => lang.value.code === currentLanguage
    )

    if (!lang) {
      const fallbackIndex =
        languagesInfo.findIndex((lang) => lang.code === 'en') ?? 0
      lang = languageOptions[fallbackIndex]
    }

    return { ...lang.value, name: lang?.label }
  }, [languageOptions, currentLanguage])

  const initialize = useCallback(async () => {
    if (howToPlaySeen) {
      if (
        challenge &&
        challenge.length === 2 &&
        challenge[0] !== challenge[1]
      ) {
        const startPromise = getArticleTitle(
          challenge[0],
          currentLanguageData.dbname
        )
        const targetPromise = getArticleTitle(
          challenge[1],
          currentLanguageData.dbname
        )

        try {
          const [start, target] = await Promise.all([
            startPromise,
            targetPromise,
          ])
          if (start.success && target.success) {
            const state = getInitialLocalStorageState(
              currentLanguage,
              challenge.join('_')
            )
            await fetchContent(
              state.current ? state.current : start.value!.titleURL,
              currentLanguage
            )
            setLocalState(state)
            setGameState((currentState) => ({
              ...currentState,
              startArticle: start.value,
              targetArticle: target.value,
            }))

            return
          }
        } catch (e) {
          onError(e as Error)
        }
      }
      getRandomArticles(
        currentLanguageData.dbname,
        getDateId(),
        currentLanguage
      )
        .then(async ([start, target]) => {
          const state = getInitialLocalStorageState(
            currentLanguage,
            challenge.join('_')
          )
          await fetchContent(
            state.current ? state.current : start.titleURL,
            currentLanguage
          )
          setGameState((currentState) => ({
            ...currentState,
            startArticle: start,
            targetArticle: target,
            isLoading: false,
          }))
        })
        .catch(onError)
    }
  }, [
    challenge,
    onError,
    howToPlaySeen,
    currentLanguageData,
    currentLanguage,
    fetchContent,
  ])

  const setLocalProperty = useCallback(
    <T,>(key: GameProperty, value: T | ((current: T) => T)) => {
      setLocalState((currentState) => {
        let newValue

        if (value instanceof Function) {
          newValue = value(currentState[key] as T)
        } else {
          newValue = value
        }

        setLocalStorageState(
          GAME_PROPERTIES[key],
          currentLanguage,
          challengeId,
          newValue
        )

        return { ...currentState, [key]: newValue }
      })
    },
    [setLocalState, currentLanguage, challengeId]
  )

  if (!currentLanguageData) {
    navigate('/')
  }

  const setHasDrawn = useCallback(() => {
    setGameProperty('hasDrawn', true)
  }, [setGameProperty])

  useEffect(() => {
    const savedLanguage = getItem('LANG')?.value

    if (urlLanguage && savedLanguage !== urlLanguage) {
      setSavedLanguage(urlLanguage)
    }
  }, [urlLanguage, setSavedLanguage])

  useEffect(() => {
    setLocalState(getInitialLocalStorageState(currentLanguage, challengeId))
    setGameState(INITIAL_GAME_STATE)
    initialize()
  }, [initialize, challengeId, currentLanguage])

  useEffect(() => {
    if (gameState.targetArticle && firstTimePlaying) {
      toast(gameState.targetArticle!.title, {
        icon: '🎯',
      })
    }
  }, [gameState.targetArticle, firstTimePlaying])

  useEffect(() => {
    if (localState.current) {
      document.title = localState.current.replaceAll('_', ' ') + ' | Linkdle'
    }
  }, [localState])

  useEffect(() => {
    if (
      !localState.winPath.length &&
      localState.hasWon &&
      gameState.targetArticle
    ) {
      const auxPath = getWinningPath(
        localState.path,
        gameState.targetArticle.titleURL
      )
      setLocalProperty('winPath', auxPath)
    }
  }, [
    localState.path,
    localState.hasWon,
    gameState.targetArticle,
    localState.winPath,
    setLocalProperty,
  ])

  const addNode = useCallback(
    (newNode: { id: string; from: string }) => {
      setLocalProperty('path', (path: Record<string, string>) => ({
        ...path,
        [newNode.id]: newNode.from,
      }))
    },
    [setLocalProperty]
  )

  const win = useCallback(() => {
    const now = Date.now()
    setLocalProperty('hasWon', true)
    setLocalProperty(
      'winTime',
      (now - (localState.startTime || now)) / 1000 / 60
    )
  }, [setLocalProperty, localState.startTime])

  const onArticleChange = useCallback(
    async (articleName: string) => {
      if (!gameState.isLoading) {
        try {
          const finalArticle = await fetchContent(articleName, currentLanguage)

          if (!localState.hasWon) {
            if (
              !localState.visited[finalArticle] &&
              gameState.startArticle?.titleURL !== finalArticle
            ) {
              if (!localState.startTime) {
                setLocalProperty('startTime', Date.now())
              }
              setLocalProperty('jumps', (current: number) => current + 1)
              setLocalProperty(
                'visited',
                (current: Record<string, boolean>) => ({
                  ...current,
                  [finalArticle]: true,
                })
              )

              addNode({ from: localState.current, id: finalArticle })
            }

            if (finalArticle === gameState.targetArticle?.titleURL) {
              win()
              setGameProperty('isModalClosed', false)
            }
          }

          navigateArticle(navigate, finalArticle, params)
          setLocalProperty('current', finalArticle)
        } catch (e: unknown) {
          onError(e as Error)
        }
      }
    },
    [
      params,
      onError,
      currentLanguage,
      addNode,
      navigate,
      gameState.startArticle,
      gameState.targetArticle,
      gameState.isLoading,
      win,
      setLocalProperty,
      localState,
      setGameProperty,
      fetchContent,
    ]
  )

  const processUrlArticle = useCallback(
    (urlArticle: string) => {
      if (
        (urlArticle === gameState.startArticle?.titleURL ||
          localState.visited[urlArticle]) &&
        localState.current !== urlArticle
      ) {
        setLocalProperty('current', urlArticle)
        fetchContent(urlArticle, currentLanguage)
      } else if (!localState.current && gameState.startArticle) {
        setLocalProperty('current', gameState.startArticle.titleURL)
        fetchContent(gameState.startArticle.titleURL, currentLanguage)
        navigateArticle(navigate, gameState.startArticle.titleURL, params)
      }
    },
    [
      gameState.startArticle,
      navigate,
      setLocalProperty,
      localState,
      params,
      currentLanguage,
      fetchContent,
    ]
  )

  const setIsArticleLoading = useCallback(
    (isArticleLoading: boolean) =>
      setGameProperty('isArticleLoading', isArticleLoading),
    [setGameProperty]
  )

  const description = intl.formatMessage({
    id: 'description-revised-2',
    defaultMessage: `Daily game that consists in navigating the wikipedia trying to get to the target article. 
    It is available in 62 languages, each one with its unique daily challenge. 
    Have fun playing!`,
  })
  const title =
    'Linkdle - ' +
    intl.formatMessage({
      id: 'title',
      defaultMessage: 'Daily Wikipedia surfing game',
    })

  const onScroll = useCallback(() => {
    if (contentRef.current) {
      if (contentRef.current.scrollTop > 1200) {
        setScrollTopShown(true)
      } else {
        setScrollTopShown(false)
      }
    }
  }, [])

  useEffect(() => {
    const container = contentRef.current

    if (container) {
      container.addEventListener('scroll', onScroll)

      return () => container.removeEventListener('scroll', onScroll)
    }
  }, [onScroll])

  return (
    <GameContextProvider value={[localState, setLocalProperty]}>
      <Helmet htmlAttributes={{ lang: intl.locale }}>
        <title>{title}</title>
        <meta name='robots' content='index, follow' />
        <meta name='description' content={description} />
        <meta property='og:description' content={description} />
        <link rel='canonical' href={`https://linkdle.com/${urlLanguage}`} />
      </Helmet>
      <Modals
        currentLanguage={currentLanguage}
        onTryAgain={onTryAgain}
        onLanguageChange={(newLang) => {
          if (newLang !== currentLanguage) {
            setGameProperty('isLoading', true)
            setLocalState(getInitialLocalStorageState(newLang, challengeId))
            setSavedLanguage(newLang)
            navigate(`/${newLang}`)
          }
        }}
      />
      <WinModal
        challengeId={challengeId}
        overlayElement={(props, contentElement) => (
          <div {...props}>
            {width > 600 && <ReactConfetti width={width} height={height} />}
            {contentElement}
          </div>
        )}
        isOpen={
          localState.hasWon &&
          !gameState.isModalClosed &&
          !gameState.isArticleLoading
        }
        winningPath={localState.winPath}
        onClose={() => setGameProperty('isModalClosed', true)}
        jumps={localState.jumps}
        winTime={localState.winTime}
        currentLanguageData={currentLanguageData}
        onTryAgain={onTryAgain}
      />
      {(gameState.isLoading || gameState.isArticleLoading) &&
        !gameState.hasDrawn && <FullScreenSpinner />}
      <ScrollContainer ref={contentRef}>
        <TopMenuContainer>
          <Topbar currentLanguage={currentLanguage} />
          <Statbar
            onYouWonClick={() => setGameProperty('isModalClosed', false)}
            currentArticle={localState.current}
            currentLanguage={currentLanguage}
            hasWon={localState.hasWon}
            isArticleLoading={gameState.isArticleLoading || gameState.isLoading}
            jumps={localState.jumps}
            startArticle={gameState.startArticle}
            targetArticle={gameState.targetArticle}
          />
        </TopMenuContainer>
        <MainContainer>
          {gameState.error ? (
            <ErrorComponent />
          ) : (
            <Routes>
              <Route
                path={`${SECOND_LEVEL_ROUTE}/:article`}
                element={
                  gameState.startArticle &&
                  gameState.targetArticle &&
                  howToPlaySeen ? (
                    <Article
                      articleContent={articleContent}
                      onError={onError}
                      setHasDrawn={setHasDrawn}
                      processUrlArticle={processUrlArticle}
                      onChange={onArticleChange}
                      currentArticle={localState.current}
                      currentLanguage={currentLanguage}
                      isGameLoading={gameState.isLoading}
                      isArticleLoading={gameState.isArticleLoading}
                      setIsArticleLoading={setIsArticleLoading}
                      scrollTop={scrollTop}
                    />
                  ) : (
                    <></>
                  )
                }
              />
              <Route
                path='*'
                element={
                  howToPlaySeen &&
                  !gameState.isLoading &&
                  gameState.startArticle ? (
                    <Navigate
                      to={
                        `${SECOND_LEVEL_ROUTE}/` +
                        (localState.current ||
                          gameState.startArticle.titleURL) +
                        '?' +
                        params.toString()
                      }
                    />
                  ) : (
                    <></>
                  )
                }
              />
            </Routes>
          )}
        </MainContainer>
        <AnimatePresence>
          {scrollTopShown && (
            <ScrollTopContainer
              transition={{ ease: 'easeInOut', duration: 0.2 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ScrollTopButton
                aria-label='Scroll top'
                onClick={scrollTopSmooth}
              >
                <Icon icon='keyboard_arrow_up' />
              </ScrollTopButton>
            </ScrollTopContainer>
          )}
        </AnimatePresence>
        <Footer />
      </ScrollContainer>
    </GameContextProvider>
  )
}
