import af from './af.json'
import ar from './ar.json'
import az from './az.json'
import be from './be.json'
import bg from './bg.json'
import bn from './bn.json'
import bs from './bs.json'
import ca from './ca.json'
import cs from './cs.json'
import cy from './cy.json'
import da from './da.json'
import de from './de.json'
import el from './el.json'
import en from './en.json'
import eo from './eo.json'
import es from './es.json'
import et from './et.json'
import eu from './eu.json'
import fa from './fa.json'
import fi from './fi.json'
import fr from './fr.json'
import ga from './ga.json'
import gl from './gl.json'
import he from './he.json'
import hi from './hi.json'
import hr from './hr.json'
import hu from './hu.json'
import hy from './hy.json'
import id from './id.json'
import is from './is.json'
import it from './it.json'
import ja from './ja.json'
import ka from './ka.json'
import kk from './kk.json'
import ko from './ko.json'
import la from './la.json'
import lt from './lt.json'
import lv from './lv.json'
import mk from './mk.json'
import ml from './ml.json'
import ms from './ms.json'
import nl from './nl.json'
import no from './no.json'
import pa from './pa.json'
import pl from './pl.json'
import pt from './pt.json'
import ro from './ro.json'
import ru from './ru.json'
import sh from './sh.json'
import sk from './sk.json'
import sl from './sl.json'
import sr from './sr.json'
import sv from './sv.json'
import ta from './ta.json'
import th from './th.json'
import tl from './tl.json'
import tr from './tr.json'
import uk from './uk.json'
import ur from './ur.json'
import uz from './uz.json'
import vi from './vi.json'
import zh from './zh.json'

export default {
  af,
  ar,
  az,
  be,
  bg,
  bn,
  bs,
  ca,
  cs,
  cy,
  da,
  de,
  el,
  en,
  eo,
  es,
  et,
  eu,
  fa,
  fi,
  fr,
  ga,
  gl,
  he,
  hi,
  hr,
  hu,
  hy,
  id,
  is,
  it,
  ja,
  ka,
  kk,
  ko,
  la,
  lt,
  lv,
  mk,
  ml,
  ms,
  nl,
  no,
  pa,
  pl,
  pt,
  ro,
  ru,
  sh,
  sk,
  sl,
  sr,
  sv,
  ta,
  th,
  tl,
  tr,
  uk,
  ur,
  uz,
  vi,
  zh,
} as Record<string, any>
