import styled from 'styled-components'

export const CustomOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const OptionTitle = styled.div`
  font-weight: var(--font-weight-medium);
`
export const OptionDescription = styled.div`
  font-size: var(--font-extra-small);
`
