import { Container } from 'components/container/Container'
import styled from 'styled-components'

export const TopbarContainer = styled(Container)`
  box-sizing: border-box;
  display: flex;
  height: var(--topbar-height);
  align-items: center;
  background: var(--topbar-background);
  width: 100%;
  justify-content: space-between;
  border-bottom: var(--topbar-border-bottom);
  z-index: 100;
`

export const LogoIcon = styled.img`
  background: linear-gradient(90deg, #f173ff 0%, #7a60ff 100%);
  border-radius: 50%;
  transform: scaleX(1);
  height: 20px;
  width: 20px;
  padding: 4px;
  margin-right: var(--size-small);
`

export const TopBarButton = styled.button`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  background: none;
  border: none;
  cursor: pointer;

  @media (max-width: 400px) {
    width: unset;
  }
`

export const Logo = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 20px;
  font-weight: 800;
  color: white;

  span {
    @media (max-width: 330px) {
      display: none;
    }
  }
`
export const Right = styled.div`
  display: flex;
  align-items: center;
  margin-right: -20px;
`
