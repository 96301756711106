import styled, { css } from 'styled-components'

type ButtonProps = {
  isSecondary?: boolean
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black);
  cursor: pointer;
  padding: var(--size-small);
  width: 100%;
  font-size: var(--font-medium);
  background: var(--button-background-primary);
  border: var(--button-border-primary);
  border-radius: var(--border-radius-small);
  font-weight: var(--font-weight-bold);

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  ${({ isSecondary }) =>
    isSecondary &&
    css`
      background: var(--button-background-secondary);
      border: var(--button-border-secondary);
    `}
`
