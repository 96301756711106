import { StylesConfig, ThemeConfig } from 'react-select'

export const SELECT_THEME: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'var(--primary-20)',
    primary25: 'var(--primary-0)',
    primary50: 'var(--primary-20)',
    primary75: 'var(--primary-20)',
    neutral0: 'var(--modal-background)',
    neutral20: 'var(--neutral-200)',
    neutral30: 'var(--neutral-300)',
    neutral80: 'var(--font-color)',
  },
})

export const SELECT_STYLES: StylesConfig = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (provided) => ({
    ...provided,
    minHeight: '38px',
    fontSize: `16px`,
    minWidth: `130px`,
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: `14px`,
    zIndex: 10,
    boxShadow: '0px 0px 0px 1px var(--neutral-20)',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '38px',
    padding: '0 6px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '38px',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: `16px`,
  }),
}
