import { useIntl } from 'react-intl'
import { LanguageOption, getLanguageOptions } from 'constants/languageOptions'
import Select from 'react-select'
import { useMemo } from 'react'
import { SELECT_STYLES, SELECT_THEME } from 'styles/select.styles'

type LanguageSelectProps = {
  currentLanguage: string
  onLanguageChange: (newLang: string) => void
}

export const LanguageSelect = ({
  currentLanguage,
  onLanguageChange,
}: LanguageSelectProps) => {
  const intl = useIntl()
  const languageOptions = useMemo(() => getLanguageOptions(intl), [intl])

  return (
    <Select
      menuPortalTarget={document.body}
      theme={SELECT_THEME}
      styles={SELECT_STYLES}
      value={languageOptions.find(
        (option) => option.value.code === currentLanguage
      )}
      onChange={(newLang) => {
        newLang && onLanguageChange((newLang as LanguageOption).value.code)
      }}
      options={languageOptions.map((option) => ({
        label: option.label,
        value: option.value,
      }))}
    />
  )
}
