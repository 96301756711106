const DAY_MS = 24 * 60 * 60 * 1000

export const getDateId = (): string => {
  const now = Date.now()

  return Math.floor(now / DAY_MS).toString()
}

export const getNextLinkdleDate = () => {
  const now = Date.now()

  const nextLinkdleMS = (Math.floor(now / DAY_MS) + 1) * DAY_MS

  return (nextLinkdleMS - now) / 1000
}
