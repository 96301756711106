import { useIntl } from 'react-intl'
import { Label } from 'components/label/Label'
import { Modal } from 'components/modal/Modal'
import { Toggle } from 'components/toggle/Toggle'
import { FormElement } from '../../../formElement/FormElement'
import { LanguageSelect } from '../../../languageSelect/LanguageSelect'
import { useThemes } from 'providers/ChangeThemeProvider'
import { useModals } from 'providers/ModalsProvider'
import { ToggleDescription } from './settingsModal.styles'

type SettingsModalProps = {
  currentLanguage: string
  onLanguageChange: (newLang: string) => void
  onClose: () => void
  isOpen: boolean
}

export const SettingsModal = ({
  currentLanguage,
  onLanguageChange,
  onClose,
  isOpen,
}: SettingsModalProps) => {
  const intl = useIntl()
  const theme = useThemes()
  const { animationsDisabled, setAnimationsDisabled } = useModals()

  return (
    <Modal
      isOpen={isOpen}
      title={`⚙️ ${intl.formatMessage({
        id: 'settings',
        defaultMessage: 'Settings',
      })}`}
      onClose={onClose}
    >
      <FormElement>
        <Label>
          {intl.formatMessage({
            id: 'current-lang-revised',
            defaultMessage: 'Language',
          })}
        </Label>
        <LanguageSelect
          currentLanguage={currentLanguage}
          onLanguageChange={(newLang) => {
            onLanguageChange(newLang)
            onClose()
          }}
        />
      </FormElement>
      <FormElement>
        <Toggle
          label={intl.formatMessage({
            id: 'dark-mode-toggle',
            defaultMessage: 'Dark mode',
          })}
          id='random-mode'
          defaultChecked={theme.isDarkMode}
          onChange={theme.toggle}
        />
      </FormElement>
      <FormElement>
        <Toggle
          label={intl.formatMessage({
            id: 'animations-disabled',
            defaultMessage: 'Disable animations',
          })}
          id='animations-disabled'
          defaultChecked={animationsDisabled}
          onChange={() => setAnimationsDisabled((current) => !current)}
        />
        <ToggleDescription>
          {intl.formatMessage({
            id: 'disable-animations-description',
            defaultMessage: 'Slightly faster article load time',
          })}
        </ToggleDescription>
      </FormElement>
      {/* {process.env.NODE_ENV !== 'production' && (
        <Toggle
          label='Random Mode'
          id='random-mode'
          defaultChecked={randomMode}
          onChange={onToggleRandomMode}
        />
      )} */}
    </Modal>
  )
}
