import { Button } from 'components/button/Button'
import styled from 'styled-components'

export const LetsGoButton = styled(Button)`
  margin-top: var(--size-large);
`

export const Section = styled.div`
  margin-bottom: var(--size-medium);
`
