import { Button } from 'components/button/Button'
import { Heart } from 'components/heart/Heart'
import { Modal } from 'components/modal/Modal'
import { FormattedMessage, useIntl } from 'react-intl'
import { HaveFun } from './announcement.styles'

type AnnouncementModalProps = {
  onClose: () => void
  isOpen: boolean
}

export const AnnouncementModal = ({
  onClose,
  isOpen,
}: AnnouncementModalProps) => {
  const intl = useIntl()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        '🎁 ' +
        intl.formatMessage({
          id: 'custom-challenge-update',
          defaultMessage: 'First major update!',
        })
      }
    >
      <div>
        {intl.formatMessage({
          id: 'working-on-features-2',
          defaultMessage: 'I have beem working on some amazing features:',
        })}
        <ul>
          <li>
            🧠{' '}
            {intl.formatMessage({
              id: 'custom-challenge-description',
              defaultMessage:
                'Create custom challenges and compete with your friends!',
            })}{' '}
            {intl.formatMessage(
              {
                id: 'custom-challenge-how-to',
                defaultMessage:
                  'To create challenges, use the {icon} button on the top right corner of the screen.',
              },
              {
                icon: (
                  <span style={{ display: 'inline-block' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        height: 22,
                        width: 22,
                      }}
                    >
                      ➕
                    </div>
                  </span>
                ),
              }
            )}
          </li>
          <li>
            🌚{' '}
            {intl.formatMessage({
              id: 'dark-mode',
              defaultMessage:
                'Dark mode! You can toggle it in the settings menu.',
            })}
          </li>
        </ul>
        <div>
          {' '}
          <FormattedMessage
            id='follow-me-on-twitter'
            defaultMessage={
              'To ensure you do not miss a thing, follow <a>{myTwitterAccount}</a> on twitter!'
            }
            values={{
              a: (chunks) => (
                <a
                  target={'_blank'}
                  rel='noreferrer'
                  href='https://mobile.twitter.com/linkdle'
                >
                  {chunks}
                </a>
              ),
              myTwitterAccount: '@linkdle',
            }}
          />
           
        </div>
        <HaveFun>
          {intl.formatMessage({
            id: 'have-fun',
            defaultMessage: 'Have fun playing!',
          })}{' '}
          <Heart />
        </HaveFun>
        <Button onClick={onClose}>
          {intl.formatMessage({
            id: 'jump-in',
            defaultMessage: 'Jump in!',
          })}
        </Button>
      </div>
    </Modal>
  )
}
