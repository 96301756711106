import { Button } from 'components/button/Button'
import { Label } from 'components/label/Label'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { FormElement } from '../../../../formElement/FormElement'
import { SearchArticle } from '../../../../searchArticle/SearchArticle'
import { ArtilceResult } from 'types/article'

export const CreateStep = ({
  currentLanguage,
  onSubmit,
}: {
  currentLanguage: string
  onClose: () => void
  onSubmit: (challenge: [ArtilceResult, ArtilceResult]) => void
}) => {
  const intl = useIntl()
  const [origin, setOrigin] = useState<ArtilceResult | undefined>()
  const [target, setTarget] = useState<ArtilceResult | undefined>()

  return (
    <form
      style={{ height: '100%' }}
      onSubmit={(e) => {
        e.preventDefault()
        if (origin && target) {
          onSubmit([origin, target])
        }
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <div>
          <FormElement>
            <Label>
              {intl.formatMessage({
                id: 'origin',
                defaultMessage: 'Origin',
              })}
            </Label>
            <SearchArticle
              onChange={(option) => {
                if (option && !Array.isArray(option)) {
                  setOrigin(option as ArtilceResult)
                }
              }}
              placeholder={intl.formatMessage({
                id: 'orange',
                defaultMessage: 'Orange',
              })}
              currentLanguage={currentLanguage}
            />
          </FormElement>
          <FormElement>
            <Label>
              {intl.formatMessage({
                id: 'target',
                defaultMessage: 'Target',
              })}
            </Label>
            <SearchArticle
              onChange={(option) => {
                if (option && !Array.isArray(option)) {
                  setTarget(option as ArtilceResult)
                }
              }}
              placeholder={intl.formatMessage({
                id: 'eminem',
                defaultMessage: 'Eminem',
              })}
              currentLanguage={currentLanguage}
            />
          </FormElement>
        </div>
        <Button
          type='submit'
          disabled={!origin || !target || origin.id === target.id}
        >
          {intl.formatMessage({
            id: 'create-button',
            defaultMessage: 'Create',
          })}
        </Button>
      </div>
    </form>
  )
}
