import { DefaultTheme } from 'styled-components'

export const lightTheme: DefaultTheme = {
  colors: {
    buttonBorder: {
      primary: 'solid 1px #a795ff',
      secondary: 'solid 1px #f7aee4',
    },
    buttonBackground: {
      primary: 'linear-gradient(90deg, #f8baff 0%, #c2b6ff 100%)',
      secondary: '#eadbff',
    },
    modalBackground: 'white',
    modalBorder: 'rgb(183 183 183)',
    topBar: 'var(--neutral-400)',
    statBar: 'var(--neutral-100)',
    menuBorder: 'lightgray',
    footer: 'var(--neutral-400)',
    font: 'rgb(40, 40, 40)',
    background: 'white',
    neutrals: {
      0: 'white',
      10: '#f9f9f9',
      20: 'hsl(0deg 0% 88%)',
      30: '#dad7d7',
      100: '#e8e8e8',
      200: '#cecdcd',
      300: 'darkgray',
      400: '#2b2b2b',
    },
    primary: {
      0: '#c2b6ff',
      10: '#a795ff',
      20: '#7a60ff',
      30: '',
      100: '',
      200: '',
      300: '',
      400: '',
    },
    secondary: {
      0: '#f173ff',
      10: '',
      20: '',
      30: '',
      100: '',
      200: '',
      300: '',
      400: '',
    },
  },
}
