import { useModals } from 'providers/ModalsProvider'
import { SettingsModal } from './components/settingsModal/SettingsModal'
import { CreateModal } from './components/createModal/CreateModal'
import { AreYouSureModal } from './components/areYouSureModal/AreYouSureModal'
import { HelpModal } from './components/helpModal/HelpModal'
import { AnnouncementModal } from './components/announcementModal/AnnouncementModal'

type ModalsProps = {
  currentLanguage: string
  onLanguageChange: (newLang: string) => void
  onTryAgain: () => void
}

export const Modals = ({
  currentLanguage,
  onLanguageChange,
  onTryAgain,
}: ModalsProps) => {
  const { announcement, areYouSure, howToPlay, create, settings } = useModals()

  return (
    <>
      <SettingsModal
        isOpen={settings.isOpen}
        onClose={settings.closeModal}
        currentLanguage={currentLanguage}
        onLanguageChange={onLanguageChange}
      />
      <CreateModal
        isOpen={create.isOpen}
        currentLanguage={currentLanguage}
        onClose={create.closeModal}
      />
      <AreYouSureModal
        onRestart={onTryAgain}
        isOpen={areYouSure.isOpen}
        onClose={areYouSure.closeModal}
      />
      <HelpModal isOpen={howToPlay.isOpen} onClose={howToPlay.closeModal} />
      <AnnouncementModal
        isOpen={announcement.isOpen}
        onClose={announcement.closeModal}
      />
    </>
  )
}
