import { Container } from 'components/container/Container'
import styled from 'styled-components'
import { motion } from 'framer-motion'

export const StatbarContainer = styled(Container)`
  box-sizing: border-box;
  display: flex;
  height: var(--statbar-height);
  align-items: center;
  background: var(--statbar-background);
  justify-content: space-between;
  border-bottom: var(--statbar-border-bottom);
  z-index: 100;
  font-size: var(--font-small);
`

export const TargetArticle = styled.div`
  display: flex;
  align-items: center;
  padding: var(--size-small) 0px;
`

export const Left = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 400px) {
    font-size: var(--font-extra-small);
  }
`

export const Separator = styled.div`
  background: var(--neutral-300);
  min-width: 1px;
  width: 1px;
  height: 20px;
  margin: 0px var(--size-medium);
`
export const Right = styled.div`
  display: flex;
  align-items: center;
`

export const Win = styled.div`
  cursor: pointer;
  font-weight: var(--font-weight-medium);
`

export const Jumps = styled.div`
  text-transform: capitalize;
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
`

export const TargetArticleAnchor = styled.a`
  display: flex;
  align-items: center;
  margin-left: var(--size-small);
`

export const CurrentArticleAnchor = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black) !important;
  background: var(--neutral-10);
  padding: var(--size-small);
  border: solid 1px var(--neutral-20);
  border-radius: var(--border-radius-small);

  div {
    margin-right: var(--size-small);
    font-size: var(--font-extra-small);
  }

  span {
    font-size: var(--font-medium);
  }

  @media (max-width: 767px) {
    div {
      display: none;
    }
  }
`

export const LoadingBar = styled(motion.div)`
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: var(--primary-20);
`

export const LoadingBarAccent = styled.div`
  @keyframes moveBar {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(calc(100vw - 100%));
    }
    100% {
      transform: translateX(0);
    }
  }

  animation-name: moveBar;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  position: absolute;
  height: 4px;
  width: 30%;
  background: linear-gradient(
    90deg,
    var(--primary-20) 0%,
    var(--secondary-0) 50%,
    var(--primary-20) 100%
  );
`
