import { FormattedMessage, useIntl } from 'react-intl'
import {
  ErrorComponentContainer,
  MessageContainer,
  MonkeyEmoji,
  SomethingWentWrong,
} from './errorComponent.styles'

export const ErrorComponent = () => {
  const intl = useIntl()

  return (
    <ErrorComponentContainer>
      <MessageContainer>
        <MonkeyEmoji>🙈</MonkeyEmoji>
        <SomethingWentWrong>
          {intl.formatMessage({
            id: 'something-went-wrong',
            defaultMessage: 'I am sorry, something went wrong',
          })}
        </SomethingWentWrong>
        <div>
          <FormattedMessage
            id='contact-me'
            defaultMessage={
              'Try refreshing the page and if the issue persists, contact me at <a>{myTwitter}</a> and I will work on solving the issue as soon as possible.'
            }
            values={{
              myTwitter: '@linkdle',
              a: (chunks) => <a href='www.twitter.com/linkdle'>{chunks}</a>,
            }}
          />
        </div>
      </MessageContainer>
    </ErrorComponentContainer>
  )
}
