import { Button } from 'components/button/Button'
import styled from 'styled-components'

export const TryAgainSection = styled.div`
  margin-bottom: var(--size-medium);
`
export const SecondaryTitle = styled.div`
  font-size: var(--font-medium);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--size-medium);
`

export const AIQuote = styled.i``

export const Disclaimer = styled.div`
  font-style: italic;
  font-size: var(--font-minuscule);
  margin-top: var(--size-medium);
`

export const WinningPath = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: var(--size-medium);

  span {
    font-size: var(--font-small);
    margin: 0px var(--size-small);
  }
`
export const PathStep = styled.div`
  font-size: var(--font-small);
`

export const ShareButton = styled(Button)`
  margin-top: var(--size-large);
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: var(--size-small);
  }
`

export const Stat = styled.div`
  margin-bottom: var(--size-medium);
`

export const CopyClipboardShareButton = styled.button`
  height: 34px;
  width: 34px;
  border-radius: 50%;
  padding: 0px;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  background: #646464;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: var(--font-medium);
  }
`
