import styled from 'styled-components'

export const HaveFun = styled.div`
  display: flex;
  align-items: center;
  margin: var(--size-large) 0px;

  div {
    margin-left: var(--size-small);
  }
`
