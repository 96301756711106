import {
  ArticleContent,
  GameProperty,
  GameState,
  LocalState,
} from './game.types'

export const SECOND_LEVEL_ROUTE = 'wiki'
export const LANGUAGE_LOCAL_KEY = 'LANG'
export const HOW_TO_PLAY_KEY = 'HOW_TO_PLAY'
export const ANIMATIONS_DISABLED = 'ANIMATIONS_DISABLED'
export const ANNOUNCEMENT_KEY = 'ANNOUNCEMENT_1'

export const INITIAL_GAME_STATE: GameState = {
  isLoading: true,
  isModalClosed: false,
  startArticle: undefined,
  targetArticle: undefined,
  hasDrawn: false,
  isArticleLoading: true,
  error: false,
}

export const INITIAL_LOCAL_STATE: LocalState = {
  jumps: 0,
  current: '',
  visited: {},
  startTime: 0,
  path: {},
  hasWon: false,
  winTime: 0,
  winSentence: '',
  winPath: [],
}

export const INITIAL_ARTICLE_CONTENT: ArticleContent = {
  articlesOfCategory: [],
  categories: [],
  subcategories: [],
  content: { title: '', html: '' },
}

export const GAME_PROPERTIES: Record<GameProperty, string> = {
  jumps: 'JUMPS',
  current: 'CURRENT',
  visited: 'VISITED',
  startTime: 'START_TIME',
  path: 'PATH',
  hasWon: 'HAS_WON',
  winTime: 'WIN_TIME',
  winSentence: 'WIN_SENTENCE',
  winPath: 'WIN_PATH',
}
