import styled from 'styled-components'

export const ShareButtons = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;

  button {
    display: flex;
  }

  @media (max-width: 330px) {
    justify-content: start;
  }
`
