import toast from 'react-hot-toast'
import { CopyClipboardShareButton } from 'components/game/components/modals/winModal/winModal.styles'
import { ShareButtons } from './share.styles'
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  TumblrShareButton,
  TumblrIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share'
import { useIntl } from 'react-intl'
import { Icon } from 'components/icon/Icon'
import { ShareContent } from 'types/shareContent'

const ICON_STYLES = {
  round: true,
  size: 34,
}

type ShareProps = {
  shareContent: ShareContent
}

export const Share = ({ shareContent }: ShareProps) => {
  const intl = useIntl()

  return (
    <ShareButtons>
      <TwitterShareButton title={shareContent.text} url={shareContent.url}>
        <TwitterIcon {...ICON_STYLES} />
      </TwitterShareButton>
      <FacebookShareButton url={shareContent.url} quote={shareContent.text}>
        <FacebookIcon {...ICON_STYLES} />
      </FacebookShareButton>
      <LinkedinShareButton url={shareContent.url} title={shareContent.text}>
        <LinkedinIcon {...ICON_STYLES} />
      </LinkedinShareButton>
      <RedditShareButton url={shareContent.url} title={shareContent.text}>
        <RedditIcon {...ICON_STYLES} />
      </RedditShareButton>
      <TumblrShareButton url={shareContent.url} title={shareContent.text}>
        <TumblrIcon {...ICON_STYLES} />
      </TumblrShareButton>
      <WhatsappShareButton url={shareContent.url} title={shareContent.text}>
        <WhatsappIcon {...ICON_STYLES} />
      </WhatsappShareButton>
      <TelegramShareButton url={shareContent.url} title={shareContent.text}>
        <TelegramIcon {...ICON_STYLES} />
      </TelegramShareButton>
      <CopyClipboardShareButton
        onClick={async () => {
          try {
            await navigator.clipboard.writeText(
              shareContent.text + ' ' + shareContent.url
            )
            toast.success(
              intl.formatMessage({
                id: 'copied',
                defaultMessage: 'Copied to the clipboard!',
              })
            )
          } catch (e) {
            console.log(e)
            toast.error(
              intl.formatMessage({
                id: 'not-copied',
                defaultMessage: 'There was an issue copying to the clipboard',
              })
            )
          }
        }}
      >
        <Icon icon='content_copy' />
      </CopyClipboardShareButton>
    </ShareButtons>
  )
}
